import { SelectChangeEvent } from "@mui/material"
import { useEffect, useState } from "react"
import PaginationKit from "src/common/components/PaginationKit"
import { useDeployer } from "src/contexts/DeployerContext"
import { IProjectInfo, useManageTokens } from "src/contexts/ManageTokensContext"
import useMemoizedState from "src/hooks/useMemorizedState"
import { getEtherscanLink, getLocaleDateString, getLocaleTimeString, shortenAddress } from "src/utils"

export const ManagePage = () => {
    const { createdTokens, setModifying_id } = useManageTokens()
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const { selectedChainId } = useDeployer()

    const onSelectPage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    useEffect(() => {
        if (createdTokens) setPageCount(rowsPerPage > 0 ? Math.ceil(createdTokens.length / rowsPerPage) : 0)
        else setPageCount(0)
        setPage(1)
    }, [rowsPerPage, createdTokens])

    const handleManage = (project: IProjectInfo) => {
        setModifying_id(project.tokenCA)
    }

    return (
        <div className='w-full flex flex-col gap-2'>
            <div className='text-[28px] md:text-[32px] text-white'>Manage Tokens</div>
            <div className='w-full h-[2px] bg-app-content' />
            <div className='w-full overflow-auto hidden md:flex justify-center'>
                <div className='w-full block text-[14px] lg:text-[16px] text-white min-w-[1100px]'>
                    <div className='w-full flex min-w-[90px] items-stretch'>
                        <div className='p-4' style={{ width: '18%' }}>Name</div>
                        <div className='p-4' style={{ width: '14%' }}>Symbol</div>
                        <div className='p-4' style={{ width: '23%' }}>Contract Address</div>
                        <div className='p-4' style={{ width: '16%' }}>Deploy Date</div>
                        <div className='p-4' style={{ width: '16%' }}>Deploy Time</div>
                        <div className='p-4 text-right' style={{ width: '13%' }}>Modify</div>
                    </div>
                    <div className='w-full flex flex-col gap-1'>
                        {
                            createdTokens.slice(rowsPerPage > 0 ? (page - 1) * rowsPerPage : 0, rowsPerPage > 0 ? Math.min(page * rowsPerPage, createdTokens.length) : createdTokens.length).map((item, index) => {
                                return (
                                    <div key={index} className='w-full flex items-stretch rounded-md bg-app-content'>
                                        <div className='p-4 flex items-center' style={{ width: '18%' }}>
                                            {item.name}
                                        </div>
                                        <div className='p-4 flex items-center' style={{ width: '14%' }}>{item.symbol}</div>
                                        <div className='p-4 flex items-center' style={{ width: '23%' }}>
                                            <a href={getEtherscanLink(selectedChainId, item.tokenCA, 'token')} target="_blank" rel="noreferrer">
                                                <span className='underline underline-offset-[4px]'>{shortenAddress(item.tokenCA, 5)}</span>
                                            </a>
                                        </div>
                                        <div className='p-4 text-[#FFF]/[.5] flex items-center' style={{ width: '16%' }}>
                                            {getLocaleDateString(new Date(Number(item.createdAt) * 1000))}
                                        </div>
                                        <div className='p-4 text-[#FFF]/[.5] flex items-center' style={{ width: '16%' }}>
                                            {getLocaleTimeString(new Date(Number(item.createdAt) * 1000))}
                                        </div>
                                        <div className='p-4 flex items-center justify-end' style={{ width: '13%' }}>
                                            <div className='cursor-pointer text-[#FFF]/[.5] underline underline-offset-[4px]' onClick={() => handleManage(item)}>
                                                Manage
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className='w-full md:hidden flex flex-col gap-2'>
                {
                    createdTokens.slice(rowsPerPage > 0 ? (page - 1) * rowsPerPage : 0, rowsPerPage > 0 ? Math.min(page * rowsPerPage, createdTokens.length) : createdTokens.length).map((item, index) => {
                        return (
                            <div key={index} className='w-full flex flex-col rounded-md bg-app-content p-4'>
                                <div className='w-full flex gap-4 items-center justify-between border-b-[1px] border-[#141619] py-2'>
                                    <div className='text-[#FFF]/[.6] text-[14px]'>Name</div>
                                    <div className='text-white text-[14px]'>
                                        {item.name}
                                    </div>
                                </div>
                                <div className='w-full flex gap-4 items-center justify-between border-b-[1px] border-[#141619] py-2'>
                                    <div className='text-[#FFF]/[.6] text-[14px]'>Symbol</div>
                                    <div className='text-white text-[14px]'>
                                        {item.symbol}
                                    </div>
                                </div>
                                <div className='w-full flex gap-4 items-center justify-between border-b-[1px] border-[#141619] py-2'>
                                    <div className='text-[#FFF]/[.6] text-[14px]'>Contract Address</div>
                                    <div className='text-white text-[14px]'>
                                        <a href={getEtherscanLink(selectedChainId, item.tokenCA, 'token')} target="_blank" rel="noreferrer">
                                            <span className='underline underline-offset-[4px]'>{shortenAddress(item.tokenCA, 5)}</span>
                                        </a>
                                    </div>
                                </div>
                                <div className='w-full flex gap-4 items-center justify-between border-b-[1px] border-[#141619] py-2'>
                                    <div className='text-[#FFF]/[.6] text-[14px]'>Deploy Date</div>
                                    <div className='text-white text-[14px]'>
                                        {getLocaleDateString(new Date(Number(item.createdAt) * 1000))}
                                    </div>
                                </div>
                                <div className='w-full flex gap-4 items-center justify-between border-b-[1px] border-[#141619] py-2'>
                                    <div className='text-[#FFF]/[.6] text-[14px]'>Deploy Time</div>
                                    <div className='text-white text-[14px]'>
                                        {getLocaleTimeString(new Date(Number(item.createdAt) * 1000))}
                                    </div>
                                </div>
                                <div className='w-full flex gap-4 items-center justify-between border-b-[1px] border-[#141619] py-2'>
                                    <div className='text-[#FFF]/[.6] text-[14px]'>Modify</div>
                                    <div className='text-white text-[14px]'>
                                        <div className='cursor-pointer text-[#FFF]/[.5] underline underline-offset-[4px]' onClick={() => handleManage(item)}>
                                            Manage
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <PaginationKit rowsPerPage={rowsPerPage} count={pageCount} page={page} onSelectRows={(event: SelectChangeEvent) => setRowsPerPage(Number(event.target.value as string))} onSelectPage={onSelectPage} />
        </div>
    )
}
