import { NewTokensProvider } from 'src/contexts'
import NewLaunch from './NewLaunch'

export const NewTokens = () => {

  return (
    <NewTokensProvider>
      <div className='w-full'>
        <NewLaunch />
      </div>
    </NewTokensProvider>
  )
}
