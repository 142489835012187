import { formatEther_Optimized } from "src/utils"
import { useNewTokens } from "src/contexts"
import { Button } from "@mui/material"
import Wallet from "src/common/Wallet"
import { useWeb3React } from "@web3-react/core"

export default function TokenInfoCheck() {
    const { account } = useWeb3React()
    const {
        tokenName,
        tokenSymbol,
        maxTx,
        maxTxPercent,
        maxWallet,
        maxWalletPercent,
        isFilledForm1,
        supply,
        tax,
        setStep
    } = useNewTokens()

    return (
        <div className='w-full flex flex-col gap-4 xl:gap- flex-1'>
            <div className='w-full bg-app-content rounded-md p-4 sm:p-6 mt-4 xl:mt-6'>
                <div className="w-full flex gap-2 sm:gap-4 items-center">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.24">
                            <path d="M21.5 6H3.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M19 12H3" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15 18H3" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                    </svg>
                    <div className='text-[16px] text-app-gray'>Please check if all data is correct</div>
                </div>
                <div className="w-full flex flex-col sm:flex-row gap-2 mt-6">
                    <div className="w-full sm:basis-1/2 flex flex-col gap-2">
                        <div className="w-full flex items-center gap-2">
                            <div className="text-app-gray text-[14px] sm:text-[16px] whitespace-nowrap w-[110px] sm:w-[120px]">Token Name:</div>
                            {tokenName && <div className="text-white text-[16px]">{tokenName}</div>}
                        </div>
                        <div className="w-full flex items-center gap-2">
                            <div className="text-app-gray text-[14px] sm:text-[16px] whitespace-nowrap w-[110px] sm:w-[120px]">Token Symbol:</div>
                            {tokenSymbol && <div className="text-white text-[16px]">{tokenSymbol}</div>}
                        </div>
                        <div className="w-full flex items-center gap-2">
                            <div className="text-app-gray text-[14px] sm:text-[16px] whitespace-nowrap w-[110px] sm:w-[120px]">Total Supply:</div>
                            {supply.gt(0) && <div className="text-white text-[16px]">{formatEther_Optimized(supply, 18, 2, true,)}</div>}
                        </div>
                        <div className="w-full flex items-center gap-2">
                            <div className="text-app-gray text-[14px] sm:text-[16px] whitespace-nowrap w-[110px] sm:w-[120px]">Max Tx:</div>
                            {tokenSymbol && maxTx.gt(0) && <div className="text-white text-[16px]">
                                {`${formatEther_Optimized(maxTx, 18, 2, true)} ${tokenSymbol} (${maxTxPercent}%)`}
                            </div>}
                        </div>
                        <div className="w-full flex items-center gap-2">
                            <div className="text-app-gray text-[14px] sm:text-[16px] whitespace-nowrap w-[110px] sm:w-[120px]">Max Wallet:</div>
                            {tokenSymbol && maxWallet.gt(0) && <div className="text-white text-[16px]">
                                {`${formatEther_Optimized(maxWallet, 18, 2, true)} ${tokenSymbol} (${maxWalletPercent}%)`}
                            </div>}
                        </div>
                    </div>
                    <div className="w-full sm:basis-1/2 flex flex-col gap-2">
                        <div className="w-full flex items-center gap-2">
                            <div className="text-app-gray text-[14px] sm:text-[16px] whitespace-nowrap w-[110px] sm:w-[120px]">Tax Receiver:</div>
                            {tax && <div className="text-white text-[16px]">
                                {tax.receiver}
                            </div>}
                        </div>
                        <div className="w-full flex items-center gap-2">
                            <div className="text-app-gray text-[14px] sm:text-[16px] whitespace-nowrap w-[110px] sm:w-[120px]">Buy Tax:</div>
                            {tax && <div className="text-white text-[16px]">
                                {tax.buyTax}
                            </div>}
                        </div>
                        <div className="w-full flex items-center gap-2">
                            <div className="text-app-gray text-[14px] sm:text-[16px] whitespace-nowrap w-[110px] sm:w-[120px]">Sell Tax:</div>
                            {tax && <div className="text-white text-[16px]">
                                {tax.sellTax}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-end'>
                {account ?
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setStep(1)}
                        sx={{ borderRadius: "8px", width: 'auto', height: '48px' }}
                        disabled={!isFilledForm1}
                    >
                        <div className='flex gap-4 items-center'>
                            <span className="text-[16px]">Project Details</span>
                            <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 13.5L7 7.5L1 1.5" stroke={isFilledForm1 ? "white" : "#777"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </Button>
                    :
                    <Wallet />
                }
            </div>
        </div>
    )
}