import { LoadingButton } from '@mui/lab'

export const SavingButton = (
    { isLoading, onClick, disabled, loadingText, staticText }:
        {
            isLoading: boolean,
            onClick: () => void,
            disabled: boolean,
            loadingText: string
            staticText: string
        }
) => {

    return (
        <LoadingButton
            variant="contained"
            sx={{ minWidth: "120px", borderRadius: "8px", height: 'auto' }}
            loading={isLoading}
            loadingPosition="start"
            onClick={onClick}
            disabled={disabled}
        >
            <span className="text-[16px]">{isLoading ? loadingText : staticText}</span>
        </LoadingButton>
    )
}
