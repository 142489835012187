import URLInput from "src/common/components/URLInput"
import { Button } from "@mui/material"
import { useState } from "react"
import NeedHelp from "../NeedHelp"
import { useNewTokens } from "src/contexts"


export default function ProjectDetails() {
    const {
        socialLinks,
        doneCreated,
        setSocialLinks,
        isFilledForm2,
        setStep
    } = useNewTokens()
    const [focusedItem, setFocusedItem] = useState(
        {
            website: false, telegram: false, twitter: false, discord: false
        }
    )

    return (
        <div className='w-full flex flex-col xl:flex-row gap-4 xl:gap-6 xl:items-stretch'>
            <div className='w-full xl:basis-2/3 fit-full-h'>
                <div className='w-full flex flex-col gap-4 xl:gap-6'>
                    <div className="text-white text-[16px]">Links</div>
                    <div className="w-full flex flex-col gap-4 xl:gap-6">
                        <div className="w-full flex flex-col md:flex-row md:items-stretch gap-4 xl:gap-6">
                            <div className="w-full md:basis-1/2">
                                <URLInput
                                    onChange={(v: string) => setSocialLinks({ ...socialLinks, website: v })}
                                    title="Website"
                                    placeholder="Enter website url..."
                                    value={socialLinks.website}
                                    readOnly={doneCreated}
                                    setFocus={(value) => setFocusedItem((v: any) => ({ ...v, website: value }))}
                                />
                            </div>
                            <div className="w-full md:basis-1/2 fit-full-h">
                                <URLInput
                                    onChange={(v: string) => setSocialLinks({ ...socialLinks, telegram: v })}
                                    title="Telegram"
                                    placeholder="Enter telegram url..."
                                    value={socialLinks.telegram}
                                    readOnly={doneCreated}
                                    setFocus={(value) => setFocusedItem((v: any) => ({ ...v, telegram: value }))}
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-col md:flex-row md:items-stretch gap-4 xl:gap-6">
                            <div className="w-full md:basis-1/2 fit-full-h">
                                <URLInput
                                    onChange={(v: string) => setSocialLinks({ ...socialLinks, twitter: v })}
                                    title="Twitter"
                                    placeholder="Enter twitter url..."
                                    value={socialLinks.twitter}
                                    readOnly={doneCreated}
                                    setFocus={(value) => setFocusedItem((v: any) => ({ ...v, twitter: value }))}
                                />
                            </div>
                            <div className="w-full md:basis-1/2 fit-full-h">
                                <URLInput
                                    onChange={(v: string) => setSocialLinks({ ...socialLinks, discord: v })}
                                    title="Discord"
                                    placeholder="Enter discord url..."
                                    value={socialLinks.discord}
                                    readOnly={doneCreated}
                                    setFocus={(value) => setFocusedItem((v: any) => ({ ...v, discord: value }))}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full border-b border-b-[#1D1F25] border-b-4" />
                    {/* <div className="text-white text-[16px]">Project description</div>
                    <DescriptionEdit
                        onChange={(v: string) => {
                            if (!doneCreated) setDescHtml(v)
                        }}
                        descHtml={descHtml}
                        setFocus={(value) => setFocusedItem((v: any) => ({ ...v, desc: value }))}
                    /> */}
                    <div className='w-full flex justify-between gap-4'>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setStep(0)}
                            sx={{ borderRadius: "8px", width: 'auto', height: '48px' }}
                        >
                            <div className='flex gap-4 items-center'>
                                <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 13.5L1 7.5L7 1.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="text-[16px]">Token Info</span>
                            </div>
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setStep(2)}
                            sx={{ borderRadius: "8px", width: 'auto', height: '48px' }}
                            disabled={!isFilledForm2}
                        >
                            <div className='flex gap-4 items-center'>
                                <span className="text-[16px]">Finalization</span>
                                <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 13.5L7 7.5L1 1.5" stroke={isFilledForm2 ? "white" : "#777"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
            <div className='w-full xl:basis-1/3 bg-app-content rounded-md flex justify-center'>
                <div className="w-full flex flex-col gap-2 p-4 xl:p-6">
                    {!focusedItem.telegram && !focusedItem.twitter && !focusedItem.discord &&
                        <div className="w-full leading-[1.3]">
                            <div className="text-white text-[14px] font-semibold mb-1">
                                Website Address
                            </div>
                            <div className="w-full flex flex-col gap-2 text-[#CCC] text-[14px]">
                                <span>Listing your website address helps to add authenticity to your project. A website is advisable before any launch; however, if you still need a website, you can leave this blank.</span>
                            </div>
                            <div className="w-full my-2">
                                <NeedHelp />
                            </div>
                        </div>
                    }
                    {focusedItem.telegram && <div className="w-full leading-[1.3]">
                        <div className="text-white text-[14px] font-semibold mb-1">
                            Telegram
                        </div>
                        <div className="w-full flex flex-col gap-2 text-[#CCC] text-[14px]">
                            <span>Do you have a telegram group? Even if it is private, please enter it here as a full URL. The information will not be shared and will only be accessible when you share the unique launch URL provided at the end of the setup.</span>
                        </div>
                        <div className="w-full my-2">
                            <NeedHelp />
                        </div>
                    </div>}
                    {focusedItem.twitter && <div className="w-full leading-[1.3]">
                        <div className="text-white text-[14px] font-semibold mb-1">
                            Twitter
                        </div>
                        <div className="w-full flex flex-col gap-2 text-[#CCC] text-[14px]">
                            <span>If you have a Twitter profile, please provide the full URL to your Twitter page. Users can navigate from your launch page to your Twitter profile.</span>
                        </div>
                        <div className="w-full my-2">
                            <NeedHelp />
                        </div>
                    </div>}
                    {focusedItem.discord && <div className="w-full leading-[1.3]">
                        <div className="text-white text-[14px] font-semibold mb-1">
                            Discord
                        </div>
                        <div className="w-full flex flex-col gap-2 text-[#CCC] text-[14px]">
                            <span>Do you have a discord server? Even if it is private, please enter it here as a full URL. The information will not be shared and will only be accessible when you share the unique launch URL provided at the end of the setup.</span>
                        </div>
                        <div className="w-full my-2">
                            <NeedHelp />
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}