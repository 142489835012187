import React, { useMemo, useState, useEffect, useRef } from 'react'
import InputBoxContainer from './InputBoxContainer'

interface InputProps {
    id: string
    title: string
    readOnly: boolean
    onChange: (val: number) => void
    setFocus: (val: boolean) => void
}

const fixedDecimals = 2

export default function PercentInput({ id, title, readOnly, onChange, setFocus }: InputProps) {
    const [isBorder, setIsBorder] = useState(false)

    const handleFocus = () => {
        setIsBorder(true)
        setFocus(true)
    }

    const handleBlur = () => {
        setIsBorder(false)
        setFocus(false)
    }

    const handleChange = (e: any) => {
        if (Number(e.currentTarget.value) >= 100) e.currentTarget.value = 100
        onChange(Number(e.currentTarget.value))
    }

    return (
        <div className={`w-full flex flex-col flex-1 rounded-md bg-app-content py-3 gap-[2px] px-4 xl:px-5 ${isBorder ? 'border border-app-primary' : ''}`}>
            <div className='text-[12px] text-app-gray'>{title}</div>
            <InputBoxContainer>
                <input
                    id={id}
                    type="text"
                    className="bg-app-content text-white text-[16px] block w-full p-0 focus:outline-none"
                    placeholder="0.0"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyPress={(e) => {
                        let ev: any = e.target;
                        ((ev.value.toString().indexOf('.') >= 0) && ev.value.toString().substring(ev.value.toString().indexOf('.') + 1).length >= fixedDecimals) && e.preventDefault();
                        ((ev.value.toString().indexOf('.') >= 0) && e.key === '.') && e.preventDefault();
                        ((ev.value.toString().length <= 0) && e.key === '.') && e.preventDefault();
                        (!((e.key === '.') || (e.key >= '0' && e.key <= '9'))) && e.preventDefault();
                    }}
                    onChange={handleChange}
                    readOnly={readOnly}
                />
            </InputBoxContainer>
        </div>
    )
}