
import { ethers } from "ethers"
import { JsonRpcProvider } from "@ethersproject/providers"

export const DEAD_ADDRESS = "0x000000000000000000000000000000000000dead"

export const BYTES32_ZERO = "0x0000000000000000000000000000000000000000000000000000000000000000"

export enum CustomChainId {
    Mainnet = 1,
    Goerli = 5,
    BSC = 56,
    BSCTestnet = 97,
    Arbitrum = 42161,
    Polygon = 137,
    Avalanche = 43114,
    Cronos = 25,
    // Andromeda = 1088,
    // Optimism = 10,
    // Moonbeam = 1284,
    // Harmony = 1666600000,
    // OasisEmerald = 42262,
    // Aurora = 1313161554,
    // Velas = 106,
    // Fantom = 250,
    // ZkSync = 324,
    // ZkSyncTestnet = 280,
}

export const Deployer_CAs: { [chainId in CustomChainId]?: any } = {
    [CustomChainId.Mainnet]: '0xf4bBe236c213C5E85a6E1C1B5E9e1Cc8b7f2Fda6',
    [CustomChainId.Goerli]: '',
    [CustomChainId.BSC]: '0xf4bBe236c213C5E85a6E1C1B5E9e1Cc8b7f2Fda6',
    [CustomChainId.BSCTestnet]: '0x80d4FaDc24E67f46adaB074cB85268C52f40C518',
    [CustomChainId.Arbitrum]: ''
}

export const UniswapRouterV2_Addresses: { [chainId in CustomChainId]?: any } = {
    [CustomChainId.Mainnet]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    [CustomChainId.Goerli]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    [CustomChainId.BSC]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    [CustomChainId.BSCTestnet]: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
    [CustomChainId.Cronos]: '0x145677FC4d9b8F19B5D56d1820c48e0443049a30',
    [CustomChainId.Arbitrum]: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506'
}

export const UniswapFactory_Addresses: { [chainId in CustomChainId]?: any } = {
    [CustomChainId.Mainnet]: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    [CustomChainId.Goerli]: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    [CustomChainId.BSC]: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
    [CustomChainId.BSCTestnet]: '0x6725F303b657a9451d8BA641348b6761A6CC7a17',
    [CustomChainId.Cronos]: '0xd590cC180601AEcD6eeADD9B7f2B7611519544f4',
    [CustomChainId.Arbitrum]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4'
}

export const CHAIN_ID_FULLNAME_MAP: { [key: CustomChainId | number]: string } = {
    [CustomChainId.Mainnet]: 'Ethereum Mainnet',
    [CustomChainId.Goerli]: 'Goerli Testnet',
    [CustomChainId.BSC]: 'BSC',
    [CustomChainId.BSCTestnet]: 'BSCTestnet',
    [CustomChainId.Arbitrum]: 'Arbitrum One',
    [CustomChainId.Polygon]: 'Polygon Mainnet',
    [CustomChainId.Avalanche]: 'Avalanche C-Chain',
    [CustomChainId.Cronos]: 'Cronos Mainnet Beta',
    // [CustomChainId.Moonbeam]: 'Moonbeam',
    // [CustomChainId.Harmony]: 'Harmony Mainnet Shard 0',
    // [CustomChainId.OasisEmerald]: 'Oasis Emerald ParaTime Mainnet',
    // [CustomChainId.Andromeda]: 'Metis Andromeda Mainnet',
    // [CustomChainId.Optimism]: 'Optimism',    
    // [CustomChainId.Aurora]: 'Aurora Mainnet',
    // [CustomChainId.Velas]: 'Velas EVM Mainnet',
    // [CustomChainId.Fantom]: 'Fantom Opera'
}

export const CHAIN_ID_NAME_MAP: { [key: CustomChainId | number]: string } = {
    [CustomChainId.Mainnet]: 'Ethereum',
    [CustomChainId.Goerli]: 'Goerli Testnet',
    [CustomChainId.BSC]: 'BNB Smart Chain',
    [CustomChainId.BSCTestnet]: 'BNB Testnet',
    [CustomChainId.Arbitrum]: 'Arbitrum',
    [CustomChainId.Polygon]: 'Polygon',
    [CustomChainId.Avalanche]: 'Avalanche',
    [CustomChainId.Cronos]: 'Cronos',
    // [CustomChainId.Moonbeam]: 'Moonbeam',
    // [CustomChainId.Harmony]: 'Harmony',
    // [CustomChainId.OasisEmerald]: 'Oasis Emerald',
    // [CustomChainId.Andromeda]: 'Metis Andromeda',
    // [CustomChainId.Optimism]: 'Optimism',    
    // [CustomChainId.Aurora]: 'Aurora',
    // [CustomChainId.Velas]: 'Velas EVM',
    // [CustomChainId.Fantom]: 'Fantom Opera'
}

export const Rpc_URLS: { [chainId in CustomChainId]?: string } = {
    [CustomChainId.Mainnet]: 'https://rpc.ankr.com/eth',
    [CustomChainId.Goerli]: 'https://eth-goerli.public.blastapi.io',
    [CustomChainId.BSC]: 'https://bsc.nodereal.io',
    [CustomChainId.BSCTestnet]: 'https://data-seed-prebsc-1-s3.binance.org:8545',
    [CustomChainId.Arbitrum]: 'https://arb1.arbitrum.io/rpc',
    [CustomChainId.Polygon]: 'https://polygon-rpc.com',
    [CustomChainId.Avalanche]: 'https://api.avax.network/ext/bc/C/rpc',
    [CustomChainId.Cronos]: 'https://evm.cronos.org',
    // [CustomChainId.Moonbeam]: 'https://rpc.api.moonbeam.network',
    // [CustomChainId.Harmony]: 'https://api.harmony.one',
    // [CustomChainId.OasisEmerald]: 'https://emerald.oasis.dev',
    // [CustomChainId.Andromeda]: 'https://andromeda.metis.io/?owner=1088',
    // [CustomChainId.Optimism]: 'https://mainnet.optimism.io',    
    // [CustomChainId.Aurora]: 'https://mainnet.aurora.dev',
    // [CustomChainId.Velas]: 'https://evmexplorer.velas.com/rpc',
    // [CustomChainId.Fantom]: 'https://rpc.ftm.tools'
}

export const Wrapped_Ethers: { [key: CustomChainId | number]: any } = {
    [CustomChainId.Mainnet]: { address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', name: 'Wrapped Ether', symbol: 'WETH', decimals: 18, logoURI: '/images/token_logos/eth.png' },
    [CustomChainId.Goerli]: { address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6', name: 'Wrapped Ether', symbol: 'WETH', decimals: 18, logoURI: '/images/token_logos/eth.png' },
    [CustomChainId.BSC]: { address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', name: 'Wrapped BNB', symbol: 'WBNB', decimals: 18, logoURI: '/images/token_logos/bnb.png' },
    [CustomChainId.BSCTestnet]: { address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd', name: 'Wrapped BNB', symbol: 'WBNB', decimals: 18, logoURI: '/images/token_logos/bnb.png' },
    [CustomChainId.Arbitrum]: { address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', name: 'Wrapped Ether', symbol: 'WETH', decimals: 18, logoURI: '/images/token_logos/eth.png' },
    [CustomChainId.Polygon]: { address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', name: 'Wrapped Matic', symbol: 'WMATIC', decimals: 18, logoURI: '/images/token_logos/matic.png' },
    [CustomChainId.Avalanche]: { address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', name: 'Wrapped AVAX', symbol: 'WAVAX', decimals: 18, logoURI: '/images/token_logos/avax.png' },
    [CustomChainId.Cronos]: { address: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', name: 'Wrapped CRO', symbol: 'CRO', decimals: 18, logoURI: '/images/token_logos/cro.png' },
    // [CustomChainId.Andromeda]: { address: '0x75cb093E4D61d2A2e65D8e0BBb01DE8d89b53481', name: 'Wrapped METIS', symbol: 'WMETIS', decimals: 18 },
    // [CustomChainId.Optimism]: { address: '0x4200000000000000000000000000000000000006', name: 'Wrapped Ether', symbol: 'WETH', decimals: 18 },
    // [CustomChainId.Moonbeam]: { address: '0xAcc15dC74880C9944775448304B263D191c6077F', name: 'Wrapped GLMR', symbol: 'WGLMR', decimals: 18 },
    // [CustomChainId.Harmony]: { address: '0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a', name: 'Wrapped One', symbol: 'WONE', decimals: 18 },
    // [CustomChainId.OasisEmerald]: { address: '0x21C718C22D52d0F3a789b752D4c2fD5908a8A733', name: 'Wrapped ROSE', symbol: 'WROSE', decimals: 18 },
    // [CustomChainId.Aurora]: { address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', name: 'Wrapped Ether', symbol: 'WETH', decimals: 18 },
    // [CustomChainId.Velas]: { address: '0xc579D1f3CF86749E05CD06f7ADe17856c2CE3126', name: 'Wrapped VLX', symbol: 'WVLX', decimals: 18 },
    // [CustomChainId.Fantom]: { address: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', name: 'Wrapped Fantom', symbol: 'WFTM', decimals: 18 },    
}

export const Native_Currencies: { [chainId in CustomChainId]?: any } = {
    [CustomChainId.Mainnet]: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    [CustomChainId.Goerli]: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    [CustomChainId.BSC]: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    [CustomChainId.BSCTestnet]: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    [CustomChainId.Arbitrum]: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    [CustomChainId.Polygon]: { name: 'Matic', symbol: 'MATIC', decimals: 18 },
    [CustomChainId.Avalanche]: { name: 'AVAX', symbol: 'AVAX', decimals: 18 },
    [CustomChainId.Cronos]: { name: 'CRO', symbol: 'CRO', decimals: 18 },
    // [CustomChainId.Moonbeam]: { name: 'GLMR', symbol: 'GLMR', decimals: 18 },
    // [CustomChainId.Harmony]: { name: 'One', symbol: 'ONE', decimals: 18 },
    // [CustomChainId.OasisEmerald]: { name: 'ROSE', symbol: 'ROSE', decimals: 18 },
    // [CustomChainId.Andromeda]: { name: 'METIS', symbol: 'METIS', decimals: 18 },
    // [CustomChainId.Optimism]: { name: 'Ether', symbol: 'ETH', decimals: 18 },    
    // [CustomChainId.Aurora]: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    // [CustomChainId.Velas]: { name: 'VLX', symbol: 'VLX', decimals: 18 },
    // [CustomChainId.Fantom]: { name: 'Fantom', symbol: 'FTM', decimals: 18 }
}

export const BlockExplorer_URLS: { [chainId in CustomChainId]?: string } = {
    [CustomChainId.Mainnet]: 'https://etherscan.io',
    [CustomChainId.Goerli]: 'https://goerli.etherscan.io/',
    [CustomChainId.BSC]: 'https://bscscan.com',
    [CustomChainId.BSCTestnet]: 'https://testnet.bscscan.com',
    [CustomChainId.Arbitrum]: 'https://arbiscan.io',
    [CustomChainId.Polygon]: 'https://polygonscan.com',
    [CustomChainId.Avalanche]: 'https://snowtrace.io',
    [CustomChainId.Cronos]: 'https://cronoscan.com',
    // [CustomChainId.Moonbeam]: 'https://moonbeam.moonscan.io',
    // [CustomChainId.Harmony]: 'https://explorer.harmony.one',
    // [CustomChainId.OasisEmerald]: 'https://explorer.emerald.oasis.dev',
    // [CustomChainId.Andromeda]: 'https://andromeda-explorer.metis.io',
    // [CustomChainId.Optimism]: 'https://optimistic.etherscan.io',    
    // [CustomChainId.Aurora]: 'https://aurorascan.dev',
    // [CustomChainId.Velas]: 'https://evmexplorer.velas.com',
    // [CustomChainId.Fantom]: 'https://ftmscan.com'
}

export const RpcProviders: { [chainId in CustomChainId]?: JsonRpcProvider } = {
    [CustomChainId.Mainnet]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.Mainnet]),
    [CustomChainId.Goerli]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.Goerli]),
    [CustomChainId.BSC]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.BSC]),
    [CustomChainId.BSCTestnet]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.BSCTestnet]),
    [CustomChainId.Arbitrum]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.Arbitrum]),
    [CustomChainId.Polygon]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.Polygon]),
    [CustomChainId.Avalanche]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.Avalanche]),
    [CustomChainId.Cronos]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.Cronos]),
    // [CustomChainId.Moonbeam]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.Moonbeam]),
    // [CustomChainId.Harmony]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.Harmony]),
    // [CustomChainId.OasisEmerald]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.OasisEmerald]),
    // [CustomChainId.Andromeda]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.Andromeda]),
    // [CustomChainId.Optimism]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.Optimism]),    
    // [CustomChainId.Aurora]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.Aurora]),
    // [CustomChainId.Velas]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.Velas]),
    // [CustomChainId.Fantom]: new ethers.providers.JsonRpcProvider(Rpc_URLS[CustomChainId.Fantom]),
}

export const AddLP_Logos: { [chainId in CustomChainId]?: any } = {
    [CustomChainId.Mainnet]: { logoURI: '/images/uniswap-uni-logo.svg', dexName: 'Uniswap' },
    [CustomChainId.Goerli]: { logoURI: '/images/uniswap-uni-logo.svg', dexName: 'Uniswap' },
    [CustomChainId.BSC]: { logoURI: '/images/pancakeswap-cake-logo.svg', dexName: 'PancakeSwap' },
    [CustomChainId.BSCTestnet]: { logoURI: '/images/pancakeswap-cake-logo.svg', dexName: 'PancakeSwap' },
    [CustomChainId.Arbitrum]: { logoURI: '/images/uniswap-uni-logo.svg', dexName: 'Uniswap' }
}