import { useNewTokens } from 'src/contexts'
import ProgressBar from './components/ProgressBar'
import TokenInfo from './components/token_info'
import Finalization from './components/finalization'
import AddingLPToUniswap from './components/finalization/AddLPToUniswap'
import ProjectDetails from './components/project_details'

export default function NewLaunch() {
    const { step, isAddingLPToUniswap, tokenName, tokenSymbol, deployedTokenCA } = useNewTokens()

    return (
        <div className='w-full'>
            <ProgressBar />
            <div className='w-full my-8 xl:my-12'>
                {isAddingLPToUniswap ?
                    <AddingLPToUniswap token2={{ name: tokenName, symbol: tokenSymbol, decimals: 18, address: deployedTokenCA, logoURI: "/images/token_logos/unknown.svg" }} />
                    :
                    <>
                        {step === 0 && <TokenInfo />}
                        {step === 1 && <ProjectDetails />}
                        {step === 2 && <Finalization />}
                    </>
                }
            </div>
        </div>
    )
}