import { BigNumber } from '@ethersproject/bignumber'
import { Button, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ISocialLinkInfo, ITaxInfo } from 'src/contexts'
import { IProjectInfo, useManageTokens } from 'src/contexts/ManageTokensContext'
import useMemoizedState from 'src/hooks/useMemorizedState'
import { useSnackbar } from 'src/hooks/useSnackbar'
import { decodeTxErrorMessage, formatEther_Optimized, getBigNumberFromInputString, shortenAddress, copyTextToClipboard } from 'src/utils'
import { SavingButton } from './SavingButton'
import TokenQtyInput from 'src/common/components/TokenQtyInput'
import WalletAddressInput from 'src/common/components/WalletAddressInput'
import PercentInput from 'src/common/components/PercentInput'
import { LoadingButton } from '@mui/lab'
import { ethers } from 'ethers'
import { useDeployer } from 'src/contexts/DeployerContext'
import { AddLP_Logos } from 'src/constants/AppConstants'
import URLInput from 'src/common/components/URLInput'
import isURL from 'validator/lib/isURL'

const MAXTX_INPUT_ID = "id_maxtx_input"
const MAXWALLET_INPUT_ID = "id_maxwallet_input"
const BUYTAX_INPUT_ID = "id_buytax_input"
const SELLTAX_INPUT_ID = "id_selltax_input"

export const EditPage = ({ _id }: { _id: string }) => {
    const [project, setProject] = useMemoizedState<IProjectInfo>(undefined)
    const {
        createdTokens,
        setModifying_id,
        modifyMaxTxAmount,
        modifyMaxWalletAmount,
        modifyBuyTaxAmount,
        modifySellTaxAmount,
        modifyTaxReceiver,
        modifyWebsiteLink,
        modifyTelegramLink,
        modifyTwitterLink,
        modifyDiscordLink,
        modifyExemptFromFees,
        modifyExemptFromLimits,
        modifyTransferOwnership,
        enableTrading,
        updateMaxTxInProject,
        updateMaxWalletInProject,
        updateBuyTaxInProject,
        updateSellTaxInProject,
        updateTaxReceiverInProject,
        updateTransferOwnershipInProject,
        updateLimitsInEffectInProject,
        updateRenounceOwnershipInProject,
        updateTradingActiveInProject,
        updateSocialLinksInProject,
        removeLimits,
        renounceOwnership
    } = useManageTokens()
    const [isLoading, setIsLoading] = useState(false)
    const [maxTx, setMaxTx] = useState<BigNumber>(BigNumber.from(0))
    const [maxWallet, setMaxWallet] = useState<BigNumber>(BigNumber.from(0))
    const [tax, setTax] = useState<ITaxInfo>({ receiver: '', buyTax: 0, sellTax: 0 })
    const [exemptFromFeesAddr, setExemptFromFeesAddr] = useState('')
    const [exemptFromLimitsAddr, setExemptFromLimitsAddr] = useState('')
    const [transferOwnershipAddr, setTransferOwnershipAddr] = useState('')
    const [isModifying, setIsModifying] = useState<any>(
        {
            maxTx: false,
            maxWallet: false,
            taxReceiver: false,
            buyTax: false,
            sellTax: false,
            enableTrading: false,
            exemptFromFees: false,
            exemptFromLimits: false,
            transferOwnership: false,
            limitsInEffect: false,
            renounceOwnership: false,
            website: false,
            telegram: false,
            twitter: false,
            discord: false
        }
    )
    const [isCopied, setIsCopied] = useState<boolean[]>([false, false, false, false, false]) //
    const snackbar = useSnackbar()
    const { selectedChainId } = useDeployer()
    const [socialLinks, setSocialLinks] = useMemoizedState<ISocialLinkInfo>({ website: '', telegram: '', twitter: '', discord: '' })

    const copyAddress = (copyStr: string, index: number) => {
        copyTextToClipboard(copyStr)
            .then(() => {
                let copied: boolean[] = [...isCopied]
                copied[index] = true
                setIsCopied(copied)
                setTimeout(() => {
                    let copied: boolean[] = [...isCopied]
                    copied[index] = false
                    setIsCopied(copied)
                }, 3000)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        const index = createdTokens.findIndex((item) => item.tokenCA === _id)
        if (index >= 0) {
            setProject(createdTokens[index])
        } else {
            setProject(undefined)
            setModifying_id('')
        }
    }, [_id])

    useEffect(() => {
        if (project) {
            init()
        }
    }, [project])

    const init = () => {
        setMaxTx(project.maxTx)
        setMaxWallet(project.maxWallet)
        setTax({ ...project.tax })
        setSocialLinks({ ...project.socialLinks })
        let el: any = document.getElementById(MAXTX_INPUT_ID)
        el.value = formatEther_Optimized(project.maxTx, 18, 18, false)
        el = document.getElementById(MAXWALLET_INPUT_ID)
        el.value = formatEther_Optimized(project.maxWallet, 18, 18, false)
        el = document.getElementById(BUYTAX_INPUT_ID)
        el.value = project.tax.buyTax
        el = document.getElementById(SELLTAX_INPUT_ID)
        el.value = project.tax.sellTax
    }

    const handleMaxTxChange = (val: string) => {
        setMaxTx(getBigNumberFromInputString(val, 18))
    }

    const handleMaxWalletChange = (val: string) => {
        setMaxWallet(getBigNumberFromInputString(val, 18))
    }

    const onModifyMaxTxValue = async () => {
        let status = false
        try {
            setIsModifying(item => ({ ...item, maxTx: true }))
            await modifyMaxTxAmount(project, maxTx).then((res: any) => {
                if (res.status) {
                    updateMaxTxInProject(project)
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, maxTx: false }))
    }

    const onModifyMaxWalletValue = async () => {
        let status = false
        try {
            setIsModifying(item => ({ ...item, maxWallet: true }))
            await modifyMaxWalletAmount(project, maxWallet).then((res: any) => {
                if (res.status) {
                    updateMaxWalletInProject(project)
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, maxWallet: false }))
    }

    const onModifyBuyTaxValue = async () => {
        let status = false
        try {
            setIsModifying(item => ({ ...item, buyTax: true }))
            await modifyBuyTaxAmount(project, BigNumber.from(Math.floor(tax.buyTax * 100))).then((res: any) => {
                if (res.status) {
                    updateBuyTaxInProject(project)
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, buyTax: false }))
    }

    const onModifySellTaxValue = async () => {
        let status = false
        try {
            setIsModifying(item => ({ ...item, sellTax: true }))
            await modifySellTaxAmount(project, BigNumber.from(Math.floor(tax.sellTax * 100))).then((res: any) => {
                if (res.status) {
                    updateSellTaxInProject(project)
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, sellTax: false }))
    }

    const onModifyTaxReceiver = async () => {
        let status = false
        try {
            setIsModifying(item => ({ ...item, taxReceiver: true }))
            await modifyTaxReceiver(project, tax.receiver).then((res: any) => {
                if (res.status) {
                    updateTaxReceiverInProject(project)
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, taxReceiver: false }))
    }

    const onModifyExemptFromFees = async () => {
        let status = false
        try {
            setIsModifying(item => ({ ...item, exemptFromFees: true }))
            await modifyExemptFromFees(project, exemptFromFeesAddr).then((res: any) => {
                if (res.status) {
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, exemptFromFees: false }))
    }

    const onModifyExemptFromLimits = async () => {
        let status = false
        try {
            setIsModifying(item => ({ ...item, exemptFromLimits: true }))
            await modifyExemptFromLimits(project, exemptFromLimitsAddr).then((res: any) => {
                if (res.status) {
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, exemptFromLimits: false }))
    }

    const onModifyTransferOwnership = async () => {
        let status = false
        try {
            setIsModifying(item => ({ ...item, transferOwnership: true }))
            await modifyTransferOwnership(project, transferOwnershipAddr).then((res: any) => {
                if (res.status) {
                    updateTransferOwnershipInProject(project)
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, transferOwnership: false }))
    }

    const onModifyWebsiteLink = async () => {
        let status = false
        try {
            setIsModifying(item => ({ ...item, website: true }))
            await modifyWebsiteLink(project, socialLinks.website).then((res: any) => {
                if (res.status) {
                    updateSocialLinksInProject(project)
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, website: false }))
    }

    const onModifyTelegramLink = async () => {
        let status = false
        try {
            setIsModifying(item => ({ ...item, telegram: true }))
            await modifyTelegramLink(project, socialLinks.telegram).then((res: any) => {
                if (res.status) {
                    updateSocialLinksInProject(project)
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, telegram: false }))
    }

    const onModifyTwitterLink = async () => {
        let status = false
        try {
            setIsModifying(item => ({ ...item, twitter: true }))
            await modifyTwitterLink(project, socialLinks.twitter).then((res: any) => {
                if (res.status) {
                    updateSocialLinksInProject(project)
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, twitter: false }))
    }

    const onModifyDiscordLink = async () => {
        let status = false
        try {
            setIsModifying(item => ({ ...item, discord: true }))
            await modifyDiscordLink(project, socialLinks.discord).then((res: any) => {
                if (res.status) {
                    updateSocialLinksInProject(project)
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, discord: false }))
    }

    const onEnableTrading = async () => {
        let status = false
        try {
            setIsModifying(item => ({ ...item, enableTrading: true }))
            await enableTrading(project).then((res: any) => {
                if (res.status) {
                    updateTradingActiveInProject(project)
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, enableTrading: false }))
    }

    const onRemoveLimitsInEffect = async () => {
        if (isModifying.limitsInEffect) return
        let status = false
        try {
            setIsModifying(item => ({ ...item, limitsInEffect: true }))
            await removeLimits(project).then((res: any) => {
                if (res.status) {
                    updateLimitsInEffectInProject(project)
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, limitsInEffect: false }))
    }

    const onRenounceOwnership = async () => {
        if (isModifying.renounceOwnership) return
        let status = false
        try {
            setIsModifying(item => ({ ...item, renounceOwnership: true }))
            await renounceOwnership(project).then((res: any) => {
                if (res.status) {
                    updateRenounceOwnershipInProject(project)
                    snackbar.snackbar.show("Successfully modified!", "success")
                    status = true
                } else {
                    snackbar.snackbar.show("Failed!", "error")
                }
            }).catch((error: any) => {
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            let err: any = error
            snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
        }
        if (!status) {
            init()
        }
        setIsModifying(item => ({ ...item, renounceOwnership: false }))
    }

    return (
        <div className='w-full'>
            <div className='flex flex-row items-center gap-4 flex-wrap'>
                <div className='flex items-center gap-4'>
                    <div className='text-[28px] md:text-[32px] text-white'>Manage Tokens</div>
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                {project && <div className='flex gap-2 items-center text-[28px] md:text-[32px] text-white'>
                    {project.name}
                </div>}
            </div>
            <div className='w-full h-[2px] bg-app-content mt-2' />
            <div className='w-full flex items-center gap-2 mt-6 justify-between'>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        if (!isLoading) setModifying_id('')
                    }
                    }
                    sx={{ borderRadius: "8px", width: '140px', height: '48px' }}
                >
                    <div className='flex gap-4 items-center'>
                        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13.5L1 7.5L7 1.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span className="text-[16px] leading-[1.3]">Back</span>
                    </div>
                </Button>
                {project && <>
                    {!project.tradingActive &&
                        <LoadingButton
                            variant="contained"
                            sx={{ width: "auto", borderRadius: "8px", height: '48px' }}
                            loading={isModifying.enableTrading}
                            loadingPosition="start"
                            onClick={onEnableTrading}
                            disabled={isModifying.enableTrading}
                        >
                            <span className="text-[16px] leading-[1.2]">{isLoading ? "Enabling..." : `Enable ${AddLP_Logos[selectedChainId].dexName}`}</span>
                        </LoadingButton>
                    }
                </>}
            </div>
            {project &&
                <div className='w-full flex flex-col-reverse lg:flex-row gap-4 xl:gap-6 lg:items-stretch my-6'>
                    <div className='w-full lg:basis-2/3 fit-full-h'>
                        <div className="w-full flex flex-col gap-4 xl:gap-6">
                            <div className="w-full flex flex-col md:flex-row md:items-stretch gap-4 xl:gap-6">
                                <div className="w-full md:basis-1/2">
                                    <div className='w-full flex gap-2 items-stretch'>
                                        <WalletAddressInput
                                            onChange={(v: string) => setExemptFromFeesAddr(v)}
                                            title="Exempt From Fees"
                                            placeholder="Enter Wallet or Smart Contract Address"
                                            value={exemptFromFeesAddr}
                                            readOnly={false}
                                            setFocus={() => { }}
                                        />
                                        <SavingButton
                                            isLoading={isModifying.exemptFromFees}
                                            onClick={onModifyExemptFromFees}
                                            disabled={isModifying.exemptFromFees}
                                            loadingText="Updating..."
                                            staticText="Update"
                                        />
                                    </div>
                                </div>
                                <div className="w-full md:basis-1/2">
                                    <div className='w-full flex gap-2 items-stretch'>
                                        <WalletAddressInput
                                            onChange={(v: string) => setExemptFromLimitsAddr(v)}
                                            title="Exempt From Limits"
                                            placeholder="Enter Wallet or Smart Contract Address"
                                            value={exemptFromLimitsAddr}
                                            readOnly={false}
                                            setFocus={() => { }}
                                        />
                                        <SavingButton
                                            isLoading={isModifying.exemptFromLimits}
                                            onClick={onModifyExemptFromLimits}
                                            disabled={isModifying.exemptFromLimits}
                                            loadingText="Updating..."
                                            staticText="Update"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-col md:flex-row md:items-stretch gap-4 xl:gap-6">
                                <div className="w-full md:basis-1/2">
                                    <div className='w-full flex gap-2 items-stretch'>
                                        <WalletAddressInput
                                            onChange={(v: string) => setTax(feeInfo => ({ ...feeInfo, receiver: v }))}
                                            title="Tax Receiver"
                                            placeholder="Enter wallet address..."
                                            value={tax.receiver}
                                            readOnly={false}
                                            setFocus={() => { }}
                                        />
                                        <SavingButton
                                            isLoading={isModifying.taxReceiver}
                                            onClick={onModifyTaxReceiver}
                                            disabled={isModifying.taxReceiver}
                                            loadingText="Updating..."
                                            staticText="Update"
                                        />
                                    </div>
                                </div>
                                <div className="w-full md:basis-1/2">
                                    <div className='w-full flex gap-2 items-stretch'>
                                        <WalletAddressInput
                                            onChange={(v: string) => setTransferOwnershipAddr(v)}
                                            title="Transfer Ownership"
                                            placeholder="Enter New Owner Address"
                                            value={transferOwnershipAddr}
                                            readOnly={false}
                                            setFocus={() => { }}
                                        />
                                        <SavingButton
                                            isLoading={isModifying.transferOwnership}
                                            onClick={onModifyTransferOwnership}
                                            disabled={isModifying.transferOwnership}
                                            loadingText="Updating..."
                                            staticText="Update"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-col md:flex-row md:items-stretch gap-4 xl:gap-6">
                                <div className="w-full md:basis-1/2">
                                    <div className='w-full flex gap-2 items-stretch'>
                                        <TokenQtyInput onChange={handleMaxTxChange} title="Max Tx" id={MAXTX_INPUT_ID} readOnly={false} setFocus={() => { }} />
                                        <SavingButton
                                            isLoading={isModifying.maxTx}
                                            onClick={onModifyMaxTxValue}
                                            disabled={isModifying.maxTx}
                                            loadingText="Updating..."
                                            staticText="Update"
                                        />
                                    </div>
                                </div>
                                <div className="w-full md:basis-1/2">
                                    <div className='w-full flex gap-2 items-stretch'>
                                        <TokenQtyInput onChange={handleMaxWalletChange} title="Max Wallet" id={MAXWALLET_INPUT_ID} readOnly={false} setFocus={() => { }} />
                                        <SavingButton
                                            isLoading={isModifying.maxWallet}
                                            onClick={onModifyMaxWalletValue}
                                            disabled={isModifying.maxWallet}
                                            loadingText="Updating..."
                                            staticText="Update"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-col md:flex-row md:items-stretch gap-4 xl:gap-6">
                                <div className="w-full md:basis-1/2">
                                    <div className='w-full flex gap-2 items-stretch'>
                                        <PercentInput
                                            onChange={(v: number) => setTax(feeInfo => ({ ...feeInfo, buyTax: v }))}
                                            title="Buy Tax"
                                            id={BUYTAX_INPUT_ID}
                                            readOnly={false}
                                            setFocus={() => { }}
                                        />
                                        <SavingButton
                                            isLoading={isModifying.buyTax}
                                            onClick={onModifyBuyTaxValue}
                                            disabled={isModifying.buyTax}
                                            loadingText="Updating..."
                                            staticText="Update"
                                        />
                                    </div>
                                </div>
                                <div className="w-full md:basis-1/2">
                                    <div className='w-full flex gap-2 items-stretch'>
                                        <PercentInput
                                            onChange={(v: number) => setTax(feeInfo => ({ ...feeInfo, sellTax: v }))}
                                            title="Sell Tax"
                                            id={SELLTAX_INPUT_ID}
                                            readOnly={false}
                                            setFocus={() => { }}
                                        />
                                        <SavingButton
                                            isLoading={isModifying.sellTax}
                                            onClick={onModifySellTaxValue}
                                            disabled={isModifying.sellTax}
                                            loadingText="Updating..."
                                            staticText="Update"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-col md:flex-row md:items-stretch gap-4 xl:gap-6">
                                <div className="w-full md:basis-1/2">
                                    <div className='w-full flex gap-2 items-stretch'>
                                        <URLInput
                                            onChange={(v: string) => setSocialLinks({ ...socialLinks, website: v })}
                                            title="Website"
                                            placeholder="Enter website url..."
                                            value={socialLinks.website}
                                            readOnly={false}
                                            setFocus={() => { }}
                                        />
                                        <SavingButton
                                            isLoading={isModifying.website}
                                            onClick={onModifyWebsiteLink}
                                            disabled={isModifying.website || !(!socialLinks.website || isURL(socialLinks.website))}
                                            loadingText="Updating..."
                                            staticText="Update"
                                        />
                                    </div>
                                </div>
                                <div className="w-full md:basis-1/2">
                                    <div className='w-full flex gap-2 items-stretch'>
                                        <URLInput
                                            onChange={(v: string) => setSocialLinks({ ...socialLinks, telegram: v })}
                                            title="Telegram"
                                            placeholder="Enter telegram url..."
                                            value={socialLinks.telegram}
                                            readOnly={false}
                                            setFocus={() => { }}
                                        />
                                        <SavingButton
                                            isLoading={isModifying.telegram}
                                            onClick={onModifyTelegramLink}
                                            disabled={isModifying.telegram || !(!socialLinks.telegram || isURL(socialLinks.telegram))}
                                            loadingText="Updating..."
                                            staticText="Update"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-col md:flex-row md:items-stretch gap-4 xl:gap-6">
                                <div className="w-full md:basis-1/2">
                                    <div className='w-full flex gap-2 items-stretch'>
                                        <URLInput
                                            onChange={(v: string) => setSocialLinks({ ...socialLinks, twitter: v })}
                                            title="Twitter"
                                            placeholder="Enter twitter url..."
                                            value={socialLinks.twitter}
                                            readOnly={false}
                                            setFocus={() => { }}
                                        />
                                        <SavingButton
                                            isLoading={isModifying.twitter}
                                            onClick={onModifyTwitterLink}
                                            disabled={isModifying.twitter || !(!socialLinks.twitter || isURL(socialLinks.twitter))}
                                            loadingText="Updating..."
                                            staticText="Update"
                                        />
                                    </div>
                                </div>
                                <div className="w-full md:basis-1/2">
                                    <div className='w-full flex gap-2 items-stretch'>
                                        <URLInput
                                            onChange={(v: string) => setSocialLinks({ ...socialLinks, discord: v })}
                                            title="Discord"
                                            placeholder="Enter discord url..."
                                            value={socialLinks.discord}
                                            readOnly={false}
                                            setFocus={() => { }}
                                        />
                                        <SavingButton
                                            isLoading={isModifying.discord}
                                            onClick={onModifyDiscordLink}
                                            disabled={isModifying.discord || !(!socialLinks.discord || isURL(socialLinks.discord))}
                                            loadingText="Updating..."
                                            staticText="Update"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full lg:basis-1/3 bg-app-content rounded-md p-6'>
                        <div className="text-white text-[14px] font-semibold mb-1">
                            Welcome To Your Token Manager
                        </div>
                        {project && <>
                            <div className="w-full mt-4 text-[#CCC] text-[14px]">
                                {`You are managing the `}<span className='text-white font-semibold'>{`${project.name} (${project.symbol})`}</span>{` token.`}
                            </div>
                            <div className="w-full mt-6 text-[#CCC] text-[14px]">
                                {`Token Name: ${project.name}`}
                            </div>
                            <div className="w-full text-[#CCC] text-[14px]">
                                {`Token Symbol: ${project.symbol}`}
                            </div>
                            <div className="w-full flex gap-[6px] text-[#CCC] text-[14px]">
                                <span>{`Token Address: ${shortenAddress(project.tokenCA, 4)}`}</span>
                                <div className='cursor-pointer' onClick={() => copyAddress(project.tokenCA, 0)}>
                                    <img src='/images/copy.png' width="20" />
                                </div>
                                <div className='flex justify-end mt-2'>
                                    <div className="relative">
                                        <div className='absolute right-0 top-0'>
                                            {isCopied[0] && <span className='text-[10px] text-white font-light whitespace-nowrap '>Address copied</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full text-[#CCC] text-[14px]">
                                {`Total Supply: ${formatEther_Optimized(project.supply, 18, 2, true)} ${project.symbol}`}
                            </div>
                            <div className="w-full text-[#CCC] text-[14px]">
                                {`Token Decimals: 18`}
                            </div>
                            <div className="w-full text-[#CCC] text-[14px]">
                                {`${AddLP_Logos[selectedChainId].dexName} Enabled: ${project.tradingActive ? "True" : "False"}`}
                            </div>
                            <div className="w-full mt-6 flex gap-2 text-[#CCC] text-[14px]">
                                <span>{`Limits in effect: ${project.limitsInEffect ? "True" : "False"}`}</span>
                                {project.limitsInEffect && <div className='text-[#ff7070] underline cursor-pointer' onClick={onRemoveLimitsInEffect}>Remove Wallet Limits</div>}
                            </div>
                            <div className="w-full text-[#CCC] text-[14px]">
                                {`Max Transaction: ${formatEther_Optimized(project.maxTx, 18, 2, true)} ${project.symbol}`}
                            </div>
                            <div className="w-full text-[#CCC] text-[14px]">
                                {`Max Wallet: ${formatEther_Optimized(project.maxWallet, 18, 2, true)} ${project.symbol}`}
                            </div>
                            <div className="w-full mt-6 text-[#CCC] text-[14px]">
                                {`Buy Tax: ${project.tax.buyTax}%`}
                            </div>
                            <div className="w-full text-[#CCC] text-[14px]">
                                {`Sell Tax: ${project.tax.sellTax}%`}
                            </div>
                            <div className="w-full mt-6 flex gap-[6px] text-[#CCC] text-[14px]">
                                <span>{`Owner: ${shortenAddress(project.owner, 4)}`}</span>
                                <div className='cursor-pointer' onClick={() => copyAddress(project.owner, 1)}>
                                    <img src='/images/copy.png' width="20" />
                                </div>
                                <div className='flex justify-end mt-2'>
                                    <div className="relative">
                                        <div className='absolute right-0 top-0'>
                                            {isCopied[1] && <span className='text-[10px] text-white font-light whitespace-nowrap '>Address copied</span>}
                                        </div>
                                    </div>
                                </div>
                                {project.owner !== ethers.constants.AddressZero && <div className='text-[#ff7070] underline cursor-pointer' onClick={onRenounceOwnership}>Renounce Ownership</div>}
                            </div>
                            <div className="w-full flex gap-[6px] text-[#CCC] text-[14px]">
                                <span>{`Tax Receiver: ${shortenAddress(project.tax.receiver, 4)}`}</span>
                                <div className='cursor-pointer' onClick={() => copyAddress(project.tax.receiver, 2)}>
                                    <img src='/images/copy.png' width="20" />
                                </div>
                                <div className='flex justify-end mt-2'>
                                    <div className="relative">
                                        <div className='absolute right-0 top-0'>
                                            {isCopied[2] && <span className='text-[10px] text-white font-light whitespace-nowrap '>Address copied</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex gap-[6px] text-[#CCC] text-[14px]">
                                <span>{`Uniswap Router: ${shortenAddress(project.router, 4)}`}</span>
                                <div className='cursor-pointer' onClick={() => copyAddress(project.router, 3)}>
                                    <img src='/images/copy.png' width="20" />
                                </div>
                                <div className='flex justify-end mt-2'>
                                    <div className="relative">
                                        <div className='absolute right-0 top-0'>
                                            {isCopied[3] && <span className='text-[10px] text-white font-light whitespace-nowrap '>Address copied</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex gap-[6px] text-[#CCC] text-[14px]">
                                <span>{`Pair Address: ${shortenAddress(project.lpPair, 4)}`}</span>
                                <div className='cursor-pointer' onClick={() => copyAddress(project.lpPair, 4)}>
                                    <img src='/images/copy.png' width="20" />
                                </div>
                                <div className='flex justify-end mt-2'>
                                    <div className="relative">
                                        <div className='absolute right-0 top-0'>
                                            {isCopied[4] && <span className='text-[10px] text-white font-light whitespace-nowrap '>Address copied</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>}
        </div >
    )
}
