export enum SidebarItem {
  DYNAMIC_SALE,
  TOKEN_DEPLOYER,
  // TEST,
  // TEST2
}

export const SIDEBAR_ROUTES: { [key: string]: { type: string, link: string, title: string } } = {
  [SidebarItem.DYNAMIC_SALE]: { type: "dropdown", link: "", title: "" },
  [SidebarItem.TOKEN_DEPLOYER]: { type: "dropdown", link: "", title: "" },
  // [SidebarItem.TEST]: { type: "link", link: "https://launchshield.io", title: "Test" },
  // [SidebarItem.TEST2]: { type: "item", link: "/test2", title: "Test2" }
}

export const SIDEBAR_SUBITEMS: { [key: string]: any[] } = {
  [SidebarItem.DYNAMIC_SALE]: [
    { type: "link", label: "New Pools", link: "https://tools.ignitethechain.com/new-pools", title: "" },
    { type: "link", label: "Manage Pools", link: "https://tools.ignitethechain.com/manage-pools", title: "" }
  ],
  [SidebarItem.TOKEN_DEPLOYER]: [
    { type: "subItem", label: "New Tokens", link: "/new-tokens", title: "Ignite | Token Deployer" },
    { type: "subItem", label: "Manage Tokens", link: "/manage-tokens", title: "Ignite | Token Manager" }
  ]
}

export const SIDEBAR_ITEMS: { [key: string]: string } = {
  [SidebarItem.DYNAMIC_SALE]: "Dynamic Sale",
  [SidebarItem.TOKEN_DEPLOYER]: "Token Deployer",
  // [SidebarItem.TEST]: "Test",
  // [SidebarItem.TEST2]: "Test2"
}
