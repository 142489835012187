import { useNewTokens } from "src/contexts"

export default function ProgressBar() {
    const { step } = useNewTokens()

    return (
        <div className='w-full'>
            <div className='w-full gap-1 flex flex-col md:flex-row'>
                <div className='basis-1/3 flex flex-col w-full gap-2'>
                    <div className={`${step >= 0 ? 'text-white' : 'text-app-gray'} text-[16px] lg:text-[16px] md:text-center`}>Token Info</div>
                    <div className={`w-full ${step >= 0 ? 'bg-app-primary' : 'bg-app-content'} h-1`} />
                </div>
                <div className='basis-1/3 flex flex-col w-full gap-2'>
                    <div className={`${step >= 1 ? 'text-white' : 'text-app-gray'} text-[16px] lg:text-[16px] md:text-center`}>Project Details</div>
                    <div className={`w-full ${step >= 1 ? 'bg-app-primary' : 'bg-app-content'} h-1`} />
                </div>
                <div className='basis-1/3 flex flex-col w-full gap-2'>
                    <div className={`${step >= 2 ? 'text-white' : 'text-app-gray'} text-[16px] lg:text-[16px] md:text-center`}>Finalization</div>
                    <div className={`w-full ${step >= 2 ? 'bg-app-primary' : 'bg-app-content'} h-1`} />
                </div>
            </div>
        </div>
    )
}