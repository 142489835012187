import TokenQtyInput from "src/common/components/TokenQtyInput"
import { formatEther_Optimized, getBigNumberFromInputString, getEtherscanLink } from "src/utils"
import { BigNumber } from "@ethersproject/bignumber"
import { useEffect, useState } from "react"
import PairSelectBox from "./PairSelectBox"
import { ITaxInfo, useNewTokens } from "src/contexts"
import TokenInfoCheck from "./TokenInfoCheck"
import NeedHelp from "../NeedHelp"
import { useWeb3React } from "@web3-react/core"
import TextInput from "src/common/components/TextInput"
import WalletAddressInput from "src/common/components/WalletAddressInput"
import PercentInput from "src/common/components/PercentInput"

const BUY_TAX_ID = "id_buy_tax"
const SELL_TAX_ID = "id_sell_tax"
const SUPPLY_INPUT_ID = "id_supply_input"
const MAXTX_INPUT_ID = "id_maxtx_input"
const MAXWALLET_INPUT_ID = "id_maxwallet_input"

export default function TokenInfo() {
    const {
        tokenName,
        setTokenName,
        tokenSymbol,
        setTokenSymbol,
        supply,
        setSupply,
        tax,
        setTax,
        maxTx,
        maxWallet,
        setMaxTx,
        setMaxWallet,
        doneCreated,
        minMaxTx,
        minMaxWallet,
        totalFeeCap
    } = useNewTokens()
    const { chainId, account } = useWeb3React()
    const [focusedItem, setFocusedItem] = useState({ name: false, symbol: false, supply: false, maxTx: false, maxWallet: false, receiver: false, buyTax: false, sellTax: false })

    const handleMaxTxChange = (val: string) => {
        setMaxTx(getBigNumberFromInputString(val, 18))
    }

    useEffect(() => {
        const el: any = document.getElementById(MAXTX_INPUT_ID)
        if (!el.value && maxTx.gt(0)) {
            el.value = formatEther_Optimized(maxTx, 18, 18, false)
        }
    }, [maxTx])

    const handleMaxWalletChange = (val: string) => {
        setMaxWallet(getBigNumberFromInputString(val, 18))
    }

    useEffect(() => {
        const el: any = document.getElementById(MAXWALLET_INPUT_ID)
        if (!el.value && maxWallet.gt(0)) {
            el.value = formatEther_Optimized(maxWallet, 18, 18, false)
        }
    }, [maxWallet])

    const handleSupplyChange = (val: string) => {
        setSupply(getBigNumberFromInputString(val, 18))
    }

    useEffect(() => {
        const el: any = document.getElementById(SUPPLY_INPUT_ID)
        if (!el.value && maxWallet.gt(0)) {
            el.value = formatEther_Optimized(supply, 18, 18, false)
        }
    }, [supply])

    const initMaxTxBox = () => {
        setMaxTx(BigNumber.from(0))
        let element: any = document.getElementById(MAXTX_INPUT_ID)
        if (element) element.value = ""
    }

    const initMaxWalletBox = () => {
        setMaxWallet(BigNumber.from(0))
        let element: any = document.getElementById(MAXWALLET_INPUT_ID)
        if (element) element.value = ""
    }

    const initSupplyBox = () => {
        setSupply(BigNumber.from(0))
        let element: any = document.getElementById(SUPPLY_INPUT_ID)
        if (element) element.value = ""
    }

    useEffect(() => {
        let el: any = document.getElementById(BUY_TAX_ID)
        if (!el.value && tax.buyTax > 0) {
            el.value = tax.buyTax
        }
        el = document.getElementById(SELL_TAX_ID)
        if (!el.value && tax.sellTax > 0) {
            el.value = tax.sellTax
        }
    }, [tax])

    const handleTokenNameInput = (v: string) => {
        setTokenName(v)
    }

    const handleTokenSymbolInput = (v: string) => {
        setTokenSymbol(v)
    }

    return (
        <div className='w-full flex flex-col lg:flex-row gap-4 xl:gap-6 lg:items-stretch'>
            <div className='w-full lg:basis-2/3 fit-full-h'>
                <div className="w-full flex flex-col gap-4 xl:gap-6">
                    <div className="w-full flex flex-col md:flex-row md:items-stretch gap-4 xl:gap-6">
                        <div className="w-full md:basis-1/3">
                            <TextInput title="Token Name: " placeholder="Enter Token Name here ..." readOnly={false} onChange={handleTokenNameInput} value={tokenName} setFocus={(value) => setFocusedItem((v: any) => ({ ...v, name: value }))} />
                        </div>
                        <div className="w-full md:basis-1/3 fit-full-h">
                            <TextInput title="Symbol: " placeholder="Enter Token Symbol here ..." readOnly={false} onChange={handleTokenSymbolInput} value={tokenSymbol} setFocus={(value) => setFocusedItem((v: any) => ({ ...v, symbol: value }))} />
                        </div>
                        <div className="w-full md:basis-1/3 fit-full-h">
                            <TokenQtyInput onChange={handleSupplyChange} title="Total Supply" id={SUPPLY_INPUT_ID} readOnly={doneCreated} setFocus={(value) => setFocusedItem((v: any) => ({ ...v, supply: value }))} />
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row md:items-stretch gap-4 xl:gap-6">
                        <div className="w-full md:basis-1/3 fit-full-h">
                            {/* <PairSelectBox onChange={handlePairedTokenInput} title="Paired Coin" pairAddressId={pairedToken ? pairedToken._id : ''} pairList={pairTokensPerChain} readOnly={doneCreated} setFocus={(value) => setFocusedItem((v: any) => ({ ...v, pairedToken: value }))} /> */}
                            <PairSelectBox />
                        </div>
                        <div className="w-full md:basis-1/3 fit-full-h">
                            <TokenQtyInput onChange={handleMaxTxChange} title="Max Tx" id={MAXTX_INPUT_ID} readOnly={doneCreated} setFocus={(value) => setFocusedItem((v: any) => ({ ...v, maxTx: value }))} />
                        </div>
                        <div className="w-full md:basis-1/3 fit-full-h">
                            <TokenQtyInput onChange={handleMaxWalletChange} title="Max Wallet" id={MAXWALLET_INPUT_ID} readOnly={doneCreated} setFocus={(value) => setFocusedItem((v: any) => ({ ...v, maxWallet: value }))} />
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row md:items-stretch gap-4 xl:gap-6">
                        <div className="w-full md:basis-1/3 fit-full-h">
                            <WalletAddressInput
                                onChange={(v: string) => {
                                    const feeInfo = { ...tax, receiver: v }
                                    setTax(feeInfo)
                                }}
                                title="Tax Receiver"
                                placeholder="Enter wallet address..."
                                value={tax.receiver}
                                readOnly={doneCreated}
                                setFocus={(value) => setFocusedItem((v: any) => ({ ...v, receiver: value }))}
                            />
                        </div>
                        <div className="w-full md:basis-1/3 fit-full-h">
                            <PercentInput
                                onChange={(v: number) => {
                                    const feeInfo = { ...tax, buyTax: v }
                                    setTax(feeInfo)
                                }}
                                title="Buy Tax"
                                id={BUY_TAX_ID}
                                readOnly={doneCreated}
                                setFocus={(value) => setFocusedItem((v: any) => ({ ...v, buyTax: value }))}
                            />
                        </div>
                        <div className="w-full md:basis-1/3 fit-full-h">
                            <PercentInput
                                onChange={(v: number) => {
                                    const feeInfo = { ...tax, sellTax: v }
                                    setTax(feeInfo)
                                }}
                                title="Sell Tax"
                                id={SELL_TAX_ID}
                                readOnly={doneCreated}
                                setFocus={(value) => setFocusedItem((v: any) => ({ ...v, sellTax: value }))}
                            />
                        </div>
                    </div>
                </div>
                <TokenInfoCheck />
            </div>
            <div className='w-full lg:basis-1/3 bg-app-content rounded-md flex justify-center'>
                <div className="w-full flex flex-col gap-2 p-4 xl:p-6">
                    {!focusedItem.symbol && !focusedItem.supply && !focusedItem.maxTx && !focusedItem.maxWallet && !focusedItem.receiver && !focusedItem.buyTax && !focusedItem.sellTax &&
                        <div className="w-full leading-[1.3]">
                            <div className="text-white text-[14px] font-semibold mb-1">
                                Token Name
                            </div>
                            <div className="w-full flex flex-col gap-[6px] text-[#CCC] text-[14px]">
                                The name of your cryptocurrency (e.g., "MyAwesomeToken").<br /><br />
                                Advice: Choose a name that is relevant to the purpose or functionality of your token. The name should be unique to avoid confusion with existing tokens. Make it catchy but also professional to attract potential users and investors.
                            </div>
                            <div className="w-full my-2">
                                <NeedHelp />
                            </div>
                        </div>
                    }
                    {focusedItem.symbol && <div className="w-full leading-[1.4]">
                        <div className="text-white text-[14px] font-semibold mb-1">
                            Token Symbol
                        </div>
                        <div className="w-full flex flex-col gap-[6px] text-[#CCC] text-[14px]">
                            A short, memorable abbreviation to identify your token (e.g., "MAT").<br /><br />
                            Advice: The symbol should be a short, abbreviation of your token name or what it represents. Make sure the symbol is unique to avoid confusion on exchanges and wallets. Double-check other existing tokens to ensure you're not duplicating a symbol.
                        </div>
                        <div className="w-full my-2">
                            <NeedHelp />
                        </div>
                    </div>}
                    {focusedItem.supply && <div className="w-full leading-[1.4]">
                        <div className="text-white text-[14px] font-semibold mb-1">
                            Total Supply
                        </div>
                        <div className="w-full flex flex-col gap-[6px] text-[#CCC] text-[14px]">
                            The maximum number of tokens that will ever exist (e.g., 1,000,000).<br /><br />
                            Advice: The total supply should align with the goals of your project. A too large supply might dilute the value, while a too small supply might limit the utility. Be transparent about why you chose a specific total supply and how it plays into your project's economics.
                        </div>
                        <div className="w-full my-2">
                            <NeedHelp />
                        </div>
                    </div>}
                    {focusedItem.maxTx && <div className="w-full leading-[1.4]">
                        <div className="text-white text-[14px] font-semibold mb-1">
                            Max Transaction
                        </div>
                        <div className="w-full flex flex-col gap-[6px] text-[#CCC] text-[14px]">
                            The maximum number of tokens that can be transferred in a single transaction (e.g., 5,000). For security this must be at least {minMaxTx}%.<br /><br />
                            Advice: This is generally used to prevent large, market-manipulating transactions. Set this limit based on your total supply and what you consider to be a "whale" transaction that could manipulate prices. This should balance between flexibility for users and stability for the token.
                        </div>
                        <div className="w-full my-2">
                            <NeedHelp />
                        </div>
                    </div>}
                    {focusedItem.maxWallet && <div className="w-full leading-[1.4]">
                        <div className="text-white text-[14px] font-semibold mb-1">
                            Max Wallet
                        </div>
                        <div className="w-full flex flex-col gap-[6px] text-[#CCC] text-[14px]">
                            The maximum number of tokens a single wallet can hold (e.g., 50,000). For security this must be at least {minMaxWallet}%.<br /><br />
                            Advice: Similar to Max TX, this is used to prevent whale behavior. Set this parameter carefully to allow room for genuine investors while limiting the concentration of tokens that could lead to price manipulation.
                        </div>
                        <div className="w-full my-2">
                            <NeedHelp />
                        </div>
                    </div>}
                    {focusedItem.receiver && <div className="w-full leading-[1.4]">
                        <div className="text-white text-[14px] font-semibold mb-1">
                            Tax Receiver
                        </div>
                        <div className="w-full flex flex-col gap-[6px] text-[#CCC] text-[14px]">
                            The wallet address that receives taxes from transactions.<br /><br />
                            Advice: This address should be highly secure and ideally multi-signature for added security. Be transparent about how these collected taxes are utilized, whether for liquidity, development, or other purposes.
                        </div>
                        <div className="w-full my-2">
                            <NeedHelp />
                        </div>
                    </div>}
                    {focusedItem.buyTax && <div className="w-full leading-[1.4]">
                        <div className="text-white text-[14px] font-semibold mb-1">
                            Buy Tax
                        </div>
                        <div className="w-full flex flex-col gap-[6px] text-[#CCC] text-[14px]">
                            The percentage tax imposed on buying transactions (e.g., 5%). For security this must be no greater than {totalFeeCap}%.<br /><br />
                            Advice: This tax can be used for different purposes. Keep the rate reasonable and competitive, and be transparent about why you are imposing this tax and where the funds will go.
                        </div>
                        <div className="w-full my-2">
                            <NeedHelp />
                        </div>
                    </div>}
                    {focusedItem.sellTax && <div className="w-full leading-[1.4]">
                        <div className="text-white text-[14px] font-semibold mb-1">
                            Sell Tax
                        </div>
                        <div className="w-full flex flex-col gap-[6px] text-[#CCC] text-[14px]">
                            The percentage tax imposed on selling transactions (e.g., 8%). For security this must be no greater than {totalFeeCap}%.<br /><br />
                            Advice: Sell taxes are often higher to discourage quick selling and price manipulation. Clearly communicate the reason for a higher sell tax and ensure it aligns with your project's long-term goals.
                        </div>
                        <div className="w-full my-2">
                            <NeedHelp />
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}