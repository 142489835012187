export default function NeedHelp() {
    return (
        <div className="w-full text-white mt-6">
            <div className="text-[20px] mb-1">
                Still need help?
            </div>
            <div className="w-full flex flex-col gap-4">
                <div className="w-full text-[#CCC] text-[16px]">
                    Check out our documentation. Visit our telegram group or email us.
                </div>
                <div className="w-full">
                    <div className="text-[16px] font-semibold mb-1">
                        Documentation:
                    </div>
                    <div className="w-full text-[#CCC] underline underline-offset-4 text-[16px]">
                        <a href="https://docs.ignitethechain.com" target="_blank" rel="noreferrer">
                            https://docs.ignitethechain.com
                        </a>
                    </div>
                </div>
                <div className="w-full">
                    <div className="text-[16px] font-semibold mb-1">
                        Telegram:
                    </div>
                    <div className="w-full text-[#CCC] underline underline-offset-4 text-[16px]">
                        <a href="https://t.me/IgniteTheChain" target="_blank" rel="noreferrer">
                            https://t.me/IgniteTheChain
                        </a>
                    </div>
                </div>
                <div className="w-full">
                    <div className="text-[16px] font-semibold mb-1">
                        Email us:
                    </div>
                    <div className="w-full text-[#CCC] underline underline-offset-4 text-[16px]">
                        <a href="mailto:info@ignitethechain.com" target="_blank" rel="noreferrer">
                            info@ignitethechain.com
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}