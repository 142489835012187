import ReactLoading from 'react-loading'
import { EditPage } from './components/EditPage'
import { EmptyPage } from './components/EmptyPage'
import { ManagePage } from './components/ManagePage'
import { useWeb3React } from '@web3-react/core'
import ConnectButton from 'src/common/ConnectButton'
import { useManageTokens } from 'src/contexts/ManageTokensContext'

export const ManageLaunch = () => {
    const { account, chainId } = useWeb3React()
    const isConnected = !!account
    const { isLoadingProjects, createdTokens, modifying_id } = useManageTokens()

    return (
        <div className='w-full flex-1 fit-full-h'>
            {!isConnected ?
                <div className='w-full flex-1 flex flex-col items-center justify-center'>
                    <div className='w-full max-w-[420px]'>
                        <ConnectButton />
                    </div>
                </div>
                :
                <>
                    {isLoadingProjects ?
                        <div className='w-full flex-1 flex flex-col items-center justify-center'>
                            <ReactLoading type={"spokes"} color="#aaa" height='64px' width='64px' />
                        </div>
                        :
                        <>
                            {createdTokens.length === 0 && <EmptyPage />}
                            {createdTokens.length > 0 && !modifying_id && <ManagePage />}
                            {createdTokens.length > 0 && modifying_id && <EditPage _id={modifying_id} />}
                        </>
                    }
                </>
            }
        </div>
    )
}
