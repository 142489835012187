interface PopupProps {
    isOpen?: boolean
    handleClose: () => void
}

const contentModalStyle = {
    maxWidth: '480px',
    maxHeight: 'calc(100vh - 125px)'
}

export default function ContactUsModal({
    isOpen,
    handleClose
}: PopupProps) {

    const handleWindowClick = (event) => {
        const modal = document.getElementById("ignite-contactus-modal")
        if (event.target.id === modal.id) {
            handleClose()
        }
    }

    return (
        <div
            id="ignite-contactus-modal"
            aria-hidden="true"
            role="dialog"
            style={{ backgroundColor: '#ffffff20', display: isOpen ? "flex" : "none" }}
            className="modal-fadeIn overflow-y-auto overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center h-modal h-full inset-0"
            onClick={(e) => handleWindowClick(e)}
        >
            <div className="px-4 w-full h-auto" style={contentModalStyle}>
                <div className={`w-full p-6 rounded-2xl shadow bg-app-content`}>
                    <div className="flex flex-row justify-between mt-2 w-full">
                        <div className='text-white text-[24px] md:text-[32px] ml-6'>
                            Manual Onboarding
                        </div>
                        <div className="flex justify-end p-2">
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-black-400 hover:text-gray-500 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                onClick={handleClose}>
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>
                        </div>
                    </div>
                    <hr className="w-full mt-1 sm:mt-2" style={{ borderTop: "1px solid #112030" }} />
                    <div className="w-full gap-2 text-[20px]">
                        <div className="w-full gap-2 text-[16px] mt-4">
                            The Ignite Dynamic Presale platform currently requires the manual onboarding of projects. If you would like to launch without providing ETH for LP then please contact us.
                        </div>
                        <div className="text-white text-[14px] md:text-[16px] my-6">
                            {`Contact `}
                            <a href={"https://t.me/Foliash"} target="_blank" rel="noreferrer">
                                <span className="underline underline-offset-[3px]">@Foliash</span>
                            </a>
                            {` or `}
                            <a href={"https://t.me/Ily_eth"} target="_blank" rel="noreferrer">
                                <span className="underline underline-offset-[3px]">@Ily_eth</span>
                            </a>
                            {` on Telegram`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}