import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider } from "@emotion/react"
import theme from "src/theme/theme"
import Layout from 'src/common/layout/Layout'
import { RefreshContextProvider } from "src/contexts"
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { NewTokens, ManageTokens } from 'src/pages'
import { SnackbarContextProvider } from 'src/hooks/useSnackbar'
import { Web3WalletProvider } from './common/We3ReactLibs/components/Web3WalletProvider'
import { DeployerProvider } from './contexts/DeployerContext'
// import devTools from 'devtools-detect'

// const production = true

function App() {
  return (
    <Web3WalletProvider>
      <ThemeProvider theme={theme}>
        <RefreshContextProvider>
          <SnackbarContextProvider>
            <BrowserRouter>
              <DeployerProvider>
                <Layout>
                  <Routes>
                    <Route path="/new-tokens" element={<NewTokens />} />
                    <Route path="/manage-tokens" element={<ManageTokens />} />
                    <Route
                      path="*"
                      element={<Navigate to="/new-tokens" replace />}
                    />
                  </Routes>
                </Layout>
              </DeployerProvider>
            </BrowserRouter>
          </SnackbarContextProvider>
          <ToastContainer />
        </RefreshContextProvider>
      </ThemeProvider>
    </Web3WalletProvider>
  )
}

export default App
