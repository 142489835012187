
export default function TitleAndLogo() {
    return (
        <div className="flex items-center gap-4">
            <a href="https://ignitethechain.com">
                <div className='w-full'>
                    <img src="/logotext.svg" alt="" />
                </div>
            </a>
        </div>
    )
}