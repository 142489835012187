import { Button } from "@mui/material"
import { useWeb3React } from "@web3-react/core"
import { shortenAddress } from 'src/utils'
import { useWallet } from "./We3ReactLibs/components/Web3WalletProvider"

function BalanceAndDisconnect() {
    const { account } = useWeb3React()
    const { disconnect } = useWallet()

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={disconnect}
            sx={{ borderRadius: "4px", backgroundColor: '#1D1F25', height: '42px' }}
        >
            <div className='w-full flex gap-4 justify-center items-center'>
                <img src='/images/wallet.png' width='24px' alt="" />
                <div className='text-white font-normal text-[14px] sm:text-[16px]'>
                    {shortenAddress(account, 3)}
                </div>
            </div>
        </Button>

    )
}

export default function Wallet() {
    const { account } = useWeb3React()
    const { setIsOpenConnectModal } = useWallet()
    const isConnected = !!account

    return (
        <div className="flex justify-center">
            {!isConnected && (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setIsOpenConnectModal(true)}
                    sx={{ borderRadius: "12px", borderColor: "#4BCEE8", width: '180px', height: '42px' }}
                >
                    <span className="text-[16px] md:text-[18px] text-white">Connect</span>
                </Button>
            )}
            {isConnected && <BalanceAndDisconnect />}
        </div>
    )
}