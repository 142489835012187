import { calculateGasMargin, getContract, isAddress } from 'src/utils'
import { BigNumber, Contract } from 'ethers'
import React, { useContext, useEffect, useState } from 'react'
import DEPLOYER_FACTORY_ABI from 'src/constants/contracts/abis/deployerFactory.json'
import ROUTER_ABI from 'src/constants/contracts/abis/router.json'
import { TransactionResponse, Web3Provider } from '@ethersproject/providers'
import { Deployer_CAs, RpcProviders, UniswapRouterV2_Addresses } from 'src/constants/AppConstants'
import useRefresh from 'src/hooks/useRefresh'
import { useWeb3React } from '@web3-react/core'
import { useDeployer } from './DeployerContext'
import useMemoizedState from 'src/hooks/useMemorizedState'
import isURL from 'validator/lib/isURL'

declare type Maybe<T> = T | null | undefined

export interface ITaxInfo {
    receiver: string
    buyTax: number
    sellTax: number
}

export interface ICreationParams {
    name: string,
    symbol: string,
    totalSupply: BigNumber,
    owner: string,
    feeReceiver: string,
    buyTax: BigNumber,
    sellTax: BigNumber,
    maxTx: BigNumber,
    maxWallet: BigNumber,
    dynamicSale: string
}

export interface ISocialLinkInfo {
    website: string
    telegram: string
    twitter: string
    discord: string
}

export interface INewTokensContext {
    step: number
    setStep: (v: number) => void
    tokenName: string,
    setTokenName: (v: string) => void
    tokenSymbol: string,
    setTokenSymbol: (v: string) => void
    supply: BigNumber
    setSupply: (v: BigNumber) => void
    maxTx: BigNumber
    setMaxTx: (v: BigNumber) => void
    maxWallet: BigNumber
    setMaxWallet: (v: BigNumber) => void
    maxTxPercent: number
    maxWalletPercent: number
    socialLinks: ISocialLinkInfo
    setSocialLinks: (links: ISocialLinkInfo) => void
    isFilledForm1: boolean
    isFilledForm2: boolean
    tax: ITaxInfo
    setTax: (v: ITaxInfo) => void
    estimatedDeployERC20Token: () => Promise<any>
    deployERC20Token: () => Promise<any>
    doneCreated: boolean
    setDoneCreated: (v: boolean) => void
    deployedTokenCA: string
    setDeployedTokenCA: (v: string) => void
    isAddingLPToUniswap: boolean
    setIsAddingLPToUniswap: (v: boolean) => void
    estimatedAddLiquidityToUniswap: (addLiquidityETH: BigNumber, tokenCA: string, amountTokenDesired: BigNumber, amountTokenMin: BigNumber, amountETHMin: BigNumber) => Promise<any>
    addLiquidityToUniswap: (addLiquidityETH: BigNumber, tokenCA: string, amountTokenDesired: BigNumber, amountTokenMin: BigNumber, amountETHMin: BigNumber) => Promise<any>
    minMaxTx: number
    minMaxWallet: number
    totalFeeCap: number
}

const NewTokensContext = React.createContext<Maybe<INewTokensContext>>(null)

export const NewTokensProvider = ({ children = null as any }) => {
    const { account, provider } = useWeb3React()
    const { slowRefresh } = useRefresh()
    const [step, setStep] = useState(0)
    const [maxTx, setMaxTx] = useState<BigNumber>(BigNumber.from(0))
    const [maxWallet, setMaxWallet] = useState<BigNumber>(BigNumber.from(0))
    const [maxTxPercent, setMaxTxPercent] = useState(0)
    const [maxWalletPercent, setMaxWalletPercent] = useState(0)
    const [tokenName, setTokenName] = useState('')
    const [tokenSymbol, setTokenSymbol] = useState('')
    const [supply, setSupply] = useState<BigNumber>(BigNumber.from(0))
    const [tax, setTax] = useState<ITaxInfo>({ receiver: '', buyTax: 0, sellTax: 0 })
    const [isFilledForm1, setIsFilledForm1] = useState(false)
    const [isFilledForm2, setIsFilledForm2] = useState(false)
    const [doneCreated, setDoneCreated] = useState(false)
    const { selectedChainId } = useDeployer()
    const [deployedTokenCA, setDeployedTokenCA] = useState('')
    const [totalFeeCap, setTotalFeeCap] = useState(0)
    const [minMaxTx, setMinMaxTx] = useState(0)
    const [minMaxWallet, setMinMaxWallet] = useState(0)
    const [isAddingLPToUniswap, setIsAddingLPToUniswap] = useState(false)
    const [socialLinks, setSocialLinks] = useMemoizedState<ISocialLinkInfo>({ website: '', telegram: '', twitter: '', discord: '' })

    useEffect(() => {
        if (supply.gt(0)) {
            let percent = Number(maxTx.mul(BigNumber.from(10000)).div(supply)) / 100
            setMaxTxPercent(percent)
        }
    }, [supply, maxTx])

    useEffect(() => {
        if (supply.gt(0)) {
            let percent = Number(maxWallet.mul(BigNumber.from(10000)).div(supply)) / 100
            setMaxWalletPercent(percent)
        }
    }, [supply, maxWallet])

    useEffect(() => {
        if (
            tokenName.length > 0 &&
            tokenSymbol.length > 0 &&
            maxTx.gt(0) && maxTx.gte(supply.mul(BigNumber.from(Math.floor(minMaxTx * 100))).div(10000)) &&
            maxWallet.gt(0) && maxWallet.gte(supply.mul(BigNumber.from(Math.floor(minMaxWallet * 100))).div(10000)) &&
            supply.gt(0) &&
            isAddress(tax.receiver ?? '') &&
            tax.buyTax <= totalFeeCap &&
            tax.sellTax <= totalFeeCap
        ) setIsFilledForm1(true)
        else setIsFilledForm1(false)
    }, [account, tokenName, tokenSymbol, maxTx, maxWallet, supply, tax])

    useEffect(() => {
        if (
            (!socialLinks.website || isURL(socialLinks.website)) &&
            (!socialLinks.twitter || isURL(socialLinks.twitter)) &&
            (!socialLinks.telegram || isURL(socialLinks.telegram)) &&
            (!socialLinks.discord || isURL(socialLinks.website))
        ) setIsFilledForm2(true)
        else setIsFilledForm2(false)
    }, [socialLinks])

    useEffect(() => {
        if (!totalFeeCap) updateTotalFeeCap()
        if (!minMaxTx || !minMaxWallet) updateMinMaxTxWallet()
    }, [slowRefresh])

    const fetchTotalFeeCap = async (factoryContract: Contract) => {
        const res = await factoryContract.TRADING_FEE_CAP()
        return res
    }

    const fetchMinMaxTx = async (factoryContract: Contract) => {
        const res = await factoryContract.MIN_MAXTX()
        return res
    }

    const fetchMinMaxWallet = async (factoryContract: Contract) => {
        const res = await factoryContract.MIN_MAXWALLET()
        return res
    }

    const updateTotalFeeCap = async () => {
        const factoryContract: Contract = getContract(Deployer_CAs[selectedChainId], DEPLOYER_FACTORY_ABI, RpcProviders[selectedChainId], account ? account : undefined)
        await fetchTotalFeeCap(factoryContract).then(async result => {
            setTotalFeeCap(Number(result) / 100)
        }).catch(error => {
            setTotalFeeCap(0)
            console.log(error)
        })
    }

    const updateMinMaxTxWallet = async () => {
        const factoryContract: Contract = getContract(Deployer_CAs[selectedChainId], DEPLOYER_FACTORY_ABI, RpcProviders[selectedChainId], account ? account : undefined)
        await fetchMinMaxTx(factoryContract).then(async result => {
            setMinMaxTx(Number(result) / 100)
        }).catch(error => {
            setMinMaxTx(0)
            console.log(error)
        })
        await fetchMinMaxWallet(factoryContract).then(async result => {
            setMinMaxWallet(Number(result) / 100)
        }).catch(error => {
            setMinMaxWallet(0)
            console.log(error)
        })
    }

    const getDeployParams = () => {
        const params = {
            name: tokenName,
            symbol: tokenSymbol,
            totalSupply: supply,
            owner: account,
            feeReceiver: tax.receiver,
            buyTax: BigNumber.from(Math.floor(tax.buyTax * 100)),
            sellTax: BigNumber.from(Math.floor(tax.sellTax * 100)),
            maxTx: maxTx,
            maxWallet: maxWallet
        }
        return params
    }

    const getGasUsed = async (estimatedGas: BigNumber, chainId: number) => {
        let gasPrice = await RpcProviders[chainId].getGasPrice()
        let gasUsed = calculateGasMargin(estimatedGas).mul(gasPrice)
        return gasUsed
    }

    const estimatedDeployERC20Token = async () => {
        const factoryContract: Contract = getContract(Deployer_CAs[selectedChainId], DEPLOYER_FACTORY_ABI, provider as Web3Provider, account ? account : undefined)

        return factoryContract.estimateGas.createToken(getDeployParams(), socialLinks).then(async estimatedGasLimit => {
            let gasUsed = await getGasUsed(estimatedGasLimit, selectedChainId)
            return gasUsed
        })
    }

    const deployERC20Token = async function () {
        if (!account || !provider) return
        const factoryContract: Contract = getContract(Deployer_CAs[selectedChainId], DEPLOYER_FACTORY_ABI, provider as Web3Provider, account ? account : undefined)
        const params = getDeployParams()
        return factoryContract.estimateGas.createToken(params, socialLinks).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return factoryContract.createToken(params, socialLinks, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return { status: res.status, events: res.events.pop() }
                })
            })
        })
    }

    const estimatedAddLiquidityToUniswap = async (addLiquidityETH: BigNumber, tokenCA: string, amountTokenDesired: BigNumber, amountTokenMin: BigNumber, amountETHMin: BigNumber) => {
        const router: Contract = getContract(UniswapRouterV2_Addresses[selectedChainId], ROUTER_ABI, provider as Web3Provider, account ? account : undefined)
        var deadline = Math.floor(Date.now() / 1000) + 900
        return router.estimateGas.addLiquidityETH(tokenCA, amountTokenDesired, amountTokenMin, amountETHMin, account, deadline, { value: addLiquidityETH }).then(async estimatedGasLimit => {
            let gasUsed = await getGasUsed(estimatedGasLimit, selectedChainId)
            return gasUsed
        })
    }

    const addLiquidityToUniswap = async function (addLiquidityETH: BigNumber, tokenCA: string, amountTokenDesired: BigNumber, amountTokenMin: BigNumber, amountETHMin: BigNumber) {
        if (!account || !provider) return
        const router: Contract = getContract(UniswapRouterV2_Addresses[selectedChainId], ROUTER_ABI, provider as Web3Provider, account ? account : undefined)
        var deadline = Math.floor(Date.now() / 1000) + 900
        return router.estimateGas.addLiquidityETH(tokenCA, amountTokenDesired, amountTokenMin, amountETHMin, account, deadline, { value: addLiquidityETH }).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return router.addLiquidityETH(tokenCA, amountTokenDesired, amountTokenMin, amountETHMin, account, deadline, { value: addLiquidityETH, gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    return (
        <NewTokensContext.Provider
            value={{
                step,
                setStep,
                tokenName,
                setTokenName,
                tokenSymbol,
                setTokenSymbol,
                supply,
                setSupply,
                maxTx,
                setMaxTx,
                maxWallet,
                setMaxWallet,
                maxTxPercent,
                maxWalletPercent,
                socialLinks,
                setSocialLinks,
                isFilledForm1,
                isFilledForm2,
                tax,
                setTax,
                estimatedDeployERC20Token,
                deployERC20Token,
                doneCreated,
                setDoneCreated,
                deployedTokenCA,
                setDeployedTokenCA,
                isAddingLPToUniswap,
                setIsAddingLPToUniswap,
                estimatedAddLiquidityToUniswap,
                addLiquidityToUniswap,
                minMaxTx,
                minMaxWallet,
                totalFeeCap
            }}
        >
            {children}
        </NewTokensContext.Provider >
    )
}

export const useNewTokens = () => {
    const context = useContext(NewTokensContext)

    if (!context) {
        throw new Error('Component rendered outside the provider tree')
    }

    return context
}

