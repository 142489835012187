import { Native_Currencies } from "src/constants/AppConstants"
import { useDeployer } from "src/contexts/DeployerContext"

export default function PairSelectBox() {
    const { selectedChainId } = useDeployer()

    return (
        <div className="w-full flex flex-col rounded-md bg-app-content gap-[2px] flex-1 pt-3 pb-1 px-4 xl:px-5">
            <div className='text-[12px] text-app-gray'>Paired Coin</div>
            <div className='w-full flex gap-4 items-center'>
                <div className='w-[22px] h-[22px]'>
                    <img src={`/images/token_logos/${Native_Currencies[selectedChainId].symbol.toLowerCase()}.png`} width="100%" alt="" />
                </div>
                <div className='text-[16px] text-white'>{Native_Currencies[selectedChainId].symbol}</div>
            </div>
        </div>
    )
}
