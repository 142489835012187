import { ManageTokensProvider } from "src/contexts/ManageTokensContext"
import { ManageLaunch } from "./ManageLaunch"

export const ManageTokens = () => {

  return (
    <ManageTokensProvider>
      <div className='w-full flex-1 fit-full-h'>
        <ManageLaunch />
      </div>
    </ManageTokensProvider>
  )
}
