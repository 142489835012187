import React, { useContext, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from '@ethersproject/bignumber'
import { ISocialLinkInfo, ITaxInfo } from './NewTokensContext'
import { Contract } from '@ethersproject/contracts'
import { calculateGasMargin, getContract } from 'src/utils'
import { useDeployer } from './DeployerContext'
import FACTORY_ABI from 'src/constants/contracts/abis/deployerFactory.json'
import TOKEN_ABI from 'src/constants/contracts/abis/igniteToken.json'
import { Deployer_CAs, RpcProviders } from 'src/constants/AppConstants'
import { TransactionReceipt, TransactionResponse, Web3Provider } from '@ethersproject/providers'

declare type Maybe<T> = T | null | undefined

const ManageTokensContext = React.createContext<Maybe<IManageTokensContext>>(null)

export interface IDateInfo {
    year: number
    month: number
    date: number
}

export interface ITimeInfo {
    hour: number
    minute: number
    second: number
}

export interface IProjectInfo {
    deployer: string
    tokenCA: string
    name: string
    symbol: string
    supply: BigNumber
    createdAt: number
    dateInfo: IDateInfo
    timeInfo: ITimeInfo
    maxTx: BigNumber
    maxWallet: BigNumber
    maxTxPercent: number
    maxWalletPercent: number
    tax: ITaxInfo
    tradingActive: boolean
    owner: string
    limitsInEffect: boolean
    lpPair: string
    router: string
    socialLinks: ISocialLinkInfo
}

export interface IManageTokensContext {
    isLoadingProjects: boolean
    setIsLoadingProjects: (v: boolean) => void
    createdTokens: IProjectInfo[]
    updateBuyTaxInProject: (project: IProjectInfo) => Promise<any>
    updateSellTaxInProject: (project: IProjectInfo) => Promise<any>
    updateTaxReceiverInProject: (project: IProjectInfo) => Promise<any>
    updateTransferOwnershipInProject: (project: IProjectInfo) => Promise<any>
    updateLimitsInEffectInProject: (project: IProjectInfo) => Promise<any>
    updateRenounceOwnershipInProject: (project: IProjectInfo) => Promise<any>
    updateMaxTxInProject: (project: IProjectInfo) => Promise<any>
    updateMaxWalletInProject: (project: IProjectInfo) => Promise<any>
    updateSocialLinksInProject: (project: IProjectInfo) => Promise<any>
    updateTradingActiveInProject: (project: IProjectInfo) => Promise<any>
    modifyMaxTxAmount: (targetProject: IProjectInfo, maxTx: BigNumber) => Promise<any>
    modifyMaxWalletAmount: (targetProject: IProjectInfo, maxWallet: BigNumber) => Promise<any>
    modifyBuyTaxAmount: (targetProject: IProjectInfo, buyTax: BigNumber) => Promise<any>
    modifySellTaxAmount: (targetProject: IProjectInfo, sellTax: BigNumber) => Promise<any>
    modifyTaxReceiver: (targetProject: IProjectInfo, receiver: string) => Promise<any>
    modifyWebsiteLink: (targetProject: IProjectInfo, link: string) => Promise<any>
    modifyTelegramLink: (targetProject: IProjectInfo, link: string) => Promise<any>
    modifyTwitterLink: (targetProject: IProjectInfo, link: string) => Promise<any>
    modifyDiscordLink: (targetProject: IProjectInfo, link: string) => Promise<any>
    modifyExemptFromFees: (targetProject: IProjectInfo, exemptFromFeesAddr: string) => Promise<any>
    modifyExemptFromLimits: (targetProject: IProjectInfo, exemptFromLimitsAddr: string) => Promise<any>
    modifyTransferOwnership: (targetProject: IProjectInfo, transferOwnershipAddr: string) => Promise<any>
    removeLimits: (targetProject: IProjectInfo) => Promise<any>
    renounceOwnership: (targetProject: IProjectInfo) => Promise<any>
    enableTrading: (targetProject: IProjectInfo) => Promise<any>
    modifying_id: string
    setModifying_id: (id: string) => void
}

export const ManageTokensProvider = ({ children = null as any }) => {
    const { account, provider } = useWeb3React()
    const [createdTokens, setCreatedTokens] = useState<IProjectInfo[]>([])
    const [isLoadingProjects, setIsLoadingProjects] = useState(false)
    const { selectedChainId } = useDeployer()
    const [modifying_id, setModifying_id] = useState('')

    const getAllCreatedTokensForAccount = async () => {
        const factoryContract: Contract = getContract(Deployer_CAs[selectedChainId], FACTORY_ABI, RpcProviders[selectedChainId], account ? account : undefined)
        setIsLoadingProjects(true)
        try {
            let res: any
            res = await factoryContract.getDeployedInfos(account)
            let p: IProjectInfo[] = []
            await Promise.all(res.map(async (item, index) => {
                try {
                    const tokenCA = item.tokenAddress
                    const name = item.tokenName
                    const symbol = item.tokenSymbol
                    const createdAt = item.timestamp
                    const deployer = item.deployer
                    const tokenContract: Contract = getContract(tokenCA, TOKEN_ABI, RpcProviders[selectedChainId], account ? account : undefined)
                    const maxTx: BigNumber = await tokenContract.maxTransaction()
                    const maxWallet: BigNumber = await tokenContract.maxWallet()
                    const supply: BigNumber = await tokenContract.totalSupply()
                    const maxTxPercent = Number(maxTx.mul(BigNumber.from(10000)).div(supply)) / 100
                    const maxWalletPercent = Number(maxWallet.mul(BigNumber.from(10000)).div(supply)) / 100
                    const d = new Date(Number(createdAt) * 1000)
                    const dateInfo: IDateInfo = { year: d.getUTCFullYear(), month: d.getUTCMonth(), date: d.getUTCDate() }
                    const timeInfo: ITimeInfo = { hour: d.getUTCHours(), minute: d.getUTCMinutes(), second: 0 }
                    const tradingActive: boolean = await tokenContract.tradingActive()
                    const taxReceiver: string = await tokenContract.taxReceiverAddress()
                    const owner: string = await tokenContract.owner()
                    const buyTax: number = Number(await tokenContract.buyTotalTax()) / 100
                    const sellTax: number = Number(await tokenContract.sellTotalTax()) / 100
                    const tax: ITaxInfo = { receiver: taxReceiver, buyTax: buyTax, sellTax: sellTax }
                    const limitsInEffect: boolean = await tokenContract.limitsInEffect()
                    const lpPair: string = await tokenContract.lpPair()
                    const router: string = await tokenContract.dexRouter()
                    const links = await tokenContract.socialLinks()
                    const socialLinks: ISocialLinkInfo = {
                        website: links[0],
                        telegram: links[1],
                        twitter: links[2],
                        discord: links[3]
                    }

                    let project: IProjectInfo = {
                        deployer: deployer,
                        tokenCA: tokenCA,
                        name: name,
                        symbol: symbol,
                        supply: supply,
                        createdAt: createdAt,
                        dateInfo: dateInfo,
                        timeInfo: timeInfo,
                        maxTx: maxTx,
                        maxWallet: maxWallet,
                        maxTxPercent: maxTxPercent,
                        maxWalletPercent: maxWalletPercent,
                        tax: tax,
                        tradingActive: tradingActive,
                        owner: owner,
                        limitsInEffect: limitsInEffect,
                        lpPair: lpPair,
                        router: router,
                        socialLinks: socialLinks
                    }
                    p.push(project)
                } catch (err) { }
            }))
            p.sort((a, b) => Number(b.createdAt) - Number(a.createdAt))
            setCreatedTokens(p)
        } catch (e) { console.log(e) }
        setIsLoadingProjects(false)
    }

    const updateBuyTaxInProject = async (project: IProjectInfo) => {
        try {
            const tokenContract: Contract = getContract(project.tokenCA, TOKEN_ABI, RpcProviders[selectedChainId], account ? account : undefined)
            const buyTax: number = Number(await tokenContract.buyTotalTax()) / 100
            const index = createdTokens.findIndex((item) => item.tokenCA === project.tokenCA)
            if (index >= 0) {
                let items: IProjectInfo[] = [...createdTokens]
                let item: IProjectInfo = items[index]
                item.tax = { ...item.tax, buyTax: buyTax }
                items[index] = item
                setCreatedTokens(items)
            }
        } catch (e) { console.log(e) }
    }

    const updateSellTaxInProject = async (project: IProjectInfo) => {
        try {
            const tokenContract: Contract = getContract(project.tokenCA, TOKEN_ABI, RpcProviders[selectedChainId], account ? account : undefined)
            const sellTax: number = Number(await tokenContract.sellTotalTax()) / 100
            const index = createdTokens.findIndex((item) => item.tokenCA === project.tokenCA)
            if (index >= 0) {
                let items: IProjectInfo[] = [...createdTokens]
                let item: IProjectInfo = items[index]
                item.tax = { ...item.tax, sellTax: sellTax }
                items[index] = item
                setCreatedTokens(items)
            }
        } catch (e) { console.log(e) }
    }

    const updateTaxReceiverInProject = async (project: IProjectInfo) => {
        try {
            const tokenContract: Contract = getContract(project.tokenCA, TOKEN_ABI, RpcProviders[selectedChainId], account ? account : undefined)
            const taxReceiver: string = await tokenContract.taxReceiverAddress()
            const index = createdTokens.findIndex((item) => item.tokenCA === project.tokenCA)
            if (index >= 0) {
                let items: IProjectInfo[] = [...createdTokens]
                let item: IProjectInfo = items[index]
                item.tax = { ...item.tax, receiver: taxReceiver }
                items[index] = item
                setCreatedTokens(items)
            }
        } catch (e) { console.log(e) }
    }

    const updateTransferOwnershipInProject = async (project: IProjectInfo) => {
        try {
            const tokenContract: Contract = getContract(project.tokenCA, TOKEN_ABI, RpcProviders[selectedChainId], account ? account : undefined)
            const owner: string = await tokenContract.owner()
            const index = createdTokens.findIndex((item) => item.tokenCA === project.tokenCA)
            if (index >= 0) {
                let items: IProjectInfo[] = [...createdTokens]
                let item: IProjectInfo = items[index]
                item.owner = owner
                items[index] = item
                setCreatedTokens(items)
            }
        } catch (e) { console.log(e) }
    }

    const updateLimitsInEffectInProject = async (project: IProjectInfo) => {
        try {
            const tokenContract: Contract = getContract(project.tokenCA, TOKEN_ABI, RpcProviders[selectedChainId], account ? account : undefined)
            const limitsInEffect: boolean = await tokenContract.limitsInEffect()
            const index = createdTokens.findIndex((item) => item.tokenCA === project.tokenCA)
            if (index >= 0) {
                let items: IProjectInfo[] = [...createdTokens]
                let item: IProjectInfo = items[index]
                item.limitsInEffect = limitsInEffect
                items[index] = item
                setCreatedTokens(items)
            }
        } catch (e) { console.log(e) }
    }

    const updateRenounceOwnershipInProject = async (project: IProjectInfo) => {
        try {
            const tokenContract: Contract = getContract(project.tokenCA, TOKEN_ABI, RpcProviders[selectedChainId], account ? account : undefined)
            const owner: string = await tokenContract.owner()
            const index = createdTokens.findIndex((item) => item.tokenCA === project.tokenCA)
            if (index >= 0) {
                let items: IProjectInfo[] = [...createdTokens]
                let item: IProjectInfo = items[index]
                item.owner = owner
                items[index] = item
                setCreatedTokens(items)
            }
        } catch (e) { console.log(e) }
    }

    const updateMaxTxInProject = async (project: IProjectInfo) => {
        try {
            const tokenContract: Contract = getContract(project.tokenCA, TOKEN_ABI, RpcProviders[selectedChainId], account ? account : undefined)
            const maxTx: BigNumber = await tokenContract.maxTransaction()
            const maxTxPercent = Number(maxTx.mul(BigNumber.from(10000)).div(project.supply)) / 100
            const index = createdTokens.findIndex((item) => item.tokenCA === project.tokenCA)
            if (index >= 0) {
                let items: IProjectInfo[] = [...createdTokens]
                let item: IProjectInfo = items[index]
                item.maxTx = maxTx
                item.maxTxPercent = maxTxPercent
                items[index] = item
                setCreatedTokens(items)
            }
        } catch (e) { console.log(e) }
    }

    const updateMaxWalletInProject = async (project: IProjectInfo) => {
        try {
            const tokenContract: Contract = getContract(project.tokenCA, TOKEN_ABI, RpcProviders[selectedChainId], account ? account : undefined)
            const maxWallet: BigNumber = await tokenContract.maxWallet()
            const maxWalletPercent = Number(maxWallet.mul(BigNumber.from(10000)).div(project.supply)) / 100
            const index = createdTokens.findIndex((item) => item.tokenCA === project.tokenCA)
            if (index >= 0) {
                let items: IProjectInfo[] = [...createdTokens]
                let item: IProjectInfo = items[index]
                item.maxWallet = maxWallet
                item.maxWalletPercent = maxWalletPercent
                items[index] = item
                setCreatedTokens(items)
            }
        } catch (e) { console.log(e) }
    }

    const updateSocialLinksInProject = async (project: IProjectInfo) => {
        try {
            const tokenContract: Contract = getContract(project.tokenCA, TOKEN_ABI, RpcProviders[selectedChainId], account ? account : undefined)
            const links = await tokenContract.socialLinks()
            const socialLinks: ISocialLinkInfo = {
                website: links[0],
                telegram: links[1],
                twitter: links[2],
                discord: links[3]
            }
            const index = createdTokens.findIndex((item) => item.tokenCA === project.tokenCA)
            if (index >= 0) {
                let items: IProjectInfo[] = [...createdTokens]
                let item: IProjectInfo = items[index]
                item.socialLinks = { ...socialLinks }
                items[index] = item
                setCreatedTokens(items)
            }
        } catch (e) { console.log(e) }
    }

    const updateTradingActiveInProject = async (project: IProjectInfo) => {
        try {
            const tokenContract: Contract = getContract(project.tokenCA, TOKEN_ABI, RpcProviders[selectedChainId], account ? account : undefined)
            const tradingActive: boolean = await tokenContract.tradingActive()
            const index = createdTokens.findIndex((item) => item.tokenCA === project.tokenCA)
            if (index >= 0) {
                let items: IProjectInfo[] = [...createdTokens]
                let item: IProjectInfo = items[index]
                item.tradingActive = tradingActive
                items[index] = item
                setCreatedTokens(items)
            }
        } catch (e) { console.log(e) }
    }

    const modifyMaxTxAmount = async (targetProject: IProjectInfo, maxTx: BigNumber) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.updateMaxTransaction(maxTx).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.updateMaxTransaction(maxTx,
                { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                    return response.wait().then((res: TransactionReceipt) => {
                        return { status: res.status, hash: response.hash }
                    })
                })
        })
    }

    const modifyMaxWalletAmount = async (targetProject: IProjectInfo, maxWallet: BigNumber) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.updateMaxWallet(maxWallet).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.updateMaxWallet(maxWallet,
                { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                    return response.wait().then((res: TransactionReceipt) => {
                        return { status: res.status, hash: response.hash }
                    })
                })
        })
    }

    const modifyBuyTaxAmount = async (targetProject: IProjectInfo, buyTax: BigNumber) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.updateBuyTax(buyTax).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.updateBuyTax(buyTax,
                { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                    return response.wait().then((res: TransactionReceipt) => {
                        return { status: res.status, hash: response.hash }
                    })
                })
        })
    }

    const modifySellTaxAmount = async (targetProject: IProjectInfo, sellTax: BigNumber) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.updateSellTax(sellTax).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.updateSellTax(sellTax,
                { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                    return response.wait().then((res: TransactionReceipt) => {
                        return { status: res.status, hash: response.hash }
                    })
                })
        })
    }

    const modifyTaxReceiver = async (targetProject: IProjectInfo, receiver: string) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.updateTaxAddress(receiver).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.updateTaxAddress(receiver,
                { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                    return response.wait().then((res: TransactionReceipt) => {
                        return { status: res.status, hash: response.hash }
                    })
                })
        })
    }

    const modifyWebsiteLink = async (targetProject: IProjectInfo, link: string) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.setWebsiteLink(link).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.setWebsiteLink(link,
                { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                    return response.wait().then((res: TransactionReceipt) => {
                        return { status: res.status, hash: response.hash }
                    })
                })
        })
    }

    const modifyTelegramLink = async (targetProject: IProjectInfo, link: string) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.setTelegramLink(link).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.setTelegramLink(link,
                { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                    return response.wait().then((res: TransactionReceipt) => {
                        return { status: res.status, hash: response.hash }
                    })
                })
        })
    }

    const modifyTwitterLink = async (targetProject: IProjectInfo, link: string) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.setTwitterLink(link).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.setTwitterLink(link,
                { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                    return response.wait().then((res: TransactionReceipt) => {
                        return { status: res.status, hash: response.hash }
                    })
                })
        })
    }

    const modifyDiscordLink = async (targetProject: IProjectInfo, link: string) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.setDiscordLink(link).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.setDiscordLink(link,
                { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                    return response.wait().then((res: TransactionReceipt) => {
                        return { status: res.status, hash: response.hash }
                    })
                })
        })
    }

    const modifyExemptFromFees = async (targetProject: IProjectInfo, exemptFromFeesAddr: string) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.setExemptFromFees(exemptFromFeesAddr, true).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.setExemptFromFees(exemptFromFeesAddr, true,
                { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                    return response.wait().then((res: TransactionReceipt) => {
                        return { status: res.status, hash: response.hash }
                    })
                })
        })
    }

    const modifyExemptFromLimits = async (targetProject: IProjectInfo, exemptFromLimitsAddr: string) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.setExemptFromLimits(exemptFromLimitsAddr, true).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.setExemptFromLimits(exemptFromLimitsAddr, true,
                { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                    return response.wait().then((res: TransactionReceipt) => {
                        return { status: res.status, hash: response.hash }
                    })
                })
        })
    }

    const modifyTransferOwnership = async (targetProject: IProjectInfo, transferOwnershipAddr: string) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.transferOwnership(transferOwnershipAddr).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.transferOwnership(transferOwnershipAddr,
                { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                    return response.wait().then((res: TransactionReceipt) => {
                        return { status: res.status, hash: response.hash }
                    })
                })
        })
    }

    const removeLimits = async (targetProject: IProjectInfo) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.removeLimits().then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.removeLimits({ gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: TransactionReceipt) => {
                    return { status: res.status, hash: response.hash }
                })
            })
        })
    }

    const renounceOwnership = async (targetProject: IProjectInfo) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.renounceOwnership().then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.renounceOwnership({ gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: TransactionReceipt) => {
                    return { status: res.status, hash: response.hash }
                })
            })
        })
    }

    const enableTrading = async (targetProject: IProjectInfo) => {
        const tokenContract: Contract = getContract(targetProject.tokenCA, TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return tokenContract.estimateGas.enableTrading().then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return tokenContract.enableTrading(
                { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                    return response.wait().then((res: TransactionReceipt) => {
                        return { status: res.status, hash: response.hash }
                    })
                })
        })
    }

    useEffect(() => {
        if (account) {
            getAllCreatedTokensForAccount()
        }
        setModifying_id('')
    }, [account])

    return (
        <ManageTokensContext.Provider
            value={{
                isLoadingProjects,
                setIsLoadingProjects,
                createdTokens,
                updateBuyTaxInProject,
                updateSellTaxInProject,
                updateTaxReceiverInProject,
                updateTransferOwnershipInProject,
                updateLimitsInEffectInProject,
                updateRenounceOwnershipInProject,
                updateMaxTxInProject,
                updateMaxWalletInProject,
                updateSocialLinksInProject,
                updateTradingActiveInProject,
                modifyMaxTxAmount,
                modifyMaxWalletAmount,
                modifyBuyTaxAmount,
                modifySellTaxAmount,
                modifyTaxReceiver,
                modifyWebsiteLink,
                modifyTelegramLink,
                modifyTwitterLink,
                modifyDiscordLink,
                removeLimits,
                renounceOwnership,
                enableTrading,
                modifying_id,
                setModifying_id,
                modifyExemptFromFees,
                modifyExemptFromLimits,
                modifyTransferOwnership
            }}
        >
            {children}
        </ManageTokensContext.Provider >
    )
}

export const useManageTokens = () => {
    const context = useContext(ManageTokensContext)

    if (!context) {
        throw new Error('Component rendered outside the provider tree')
    }

    return context
}

