import { CustomChainId } from "src/constants/AppConstants"

export enum ChainItem {
    Mainnet,
    // Goerli,
    BSC,
    // BSC_Testnet,
    // Cronos,
    // Arbitrum

}

export const CHAIN_ITEMS = {
    [ChainItem.Mainnet]: CustomChainId.Mainnet,
    // [ChainItem.Goerli]: CustomChainId.Goerli,
    [ChainItem.BSC]: CustomChainId.BSC,
    // [ChainItem.BSC_Testnet]: CustomChainId.BSCTestnet,
    // [ChainItem.Cronos]: CustomChainId.Cronos,
    // [ChainItem.Arbitrum]: CustomChainId.Arbitrum
}