import React, { useContext, useEffect, useState } from 'react'
import { CHAIN_ITEMS } from 'src/common/layout/SupportChainsConstants'

declare type Maybe<T> = T | null | undefined

export interface IDeployerContext {
    selectedChainId: number
    setSelectedChainId: (v: number) => void
}

const DeployerContext = React.createContext<Maybe<IDeployerContext>>(null)

export const DeployerProvider = ({ children = null as any }) => {
    const [selectedChainId, setSelectedChainId] = useState(CHAIN_ITEMS[Object.keys(CHAIN_ITEMS)[0]])

    return (
        <DeployerContext.Provider
            value={{
                selectedChainId,
                setSelectedChainId
            }}
        >
            {children}
        </DeployerContext.Provider >
    )
}

export const useDeployer = () => {
    const context = useContext(DeployerContext)

    if (!context) {
        throw new Error('Component rendered outside the provider tree')
    }

    return context
}
